import React from "react";
import { FaBars } from "react-icons/fa";
import "./NavBar.scss";
import { useState } from "react";

const NavBar = (props) => {
  const { items, selectedPage, offset, onItemClick } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <>
      <div id="navbar" className={`${offset !== 0 && "with-bg"}`}>
        <img
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          src={require("./assets/logo.svg")}
          alt="Giantpaw's logo"
        />
        <ul className="desktop-list">
          {items.map((item, i) => (
            <li
              onClick={() => onItemClick(item, i)}
              className={`${selectedPage === i ? "active" : ""}`}
              key={i}
            >
              {item.title.toUpperCase()}
            </li>
          ))}
        </ul>
        <button onClick={() => setMenuOpen(!menuOpen)} className="hamburger">
          <FaBars className="icon" />
        </button>
      </div>
      <div
        style={{ height: menuOpen ? 100 + items.length * 60 : 0 }}
        className="mobile-menu"
      >
        <ul>
          {items.map((item, i) => (
            <li
              onClick={() => {
                setMenuOpen(false);
                onItemClick(item, i);
              }}
              className={`${selectedPage === i ? "active" : ""}`}
              key={i}
            >
              <p>{item.title.toUpperCase()}</p>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default NavBar;
