import React from "react";
import "./WhatsNew.scss";

const Post = (props) => {
  const { post } = props;
  return (
    <div className="post">
      <img className="desktop-img" alt="Post img" src={post.img} />
      <div className="texts">
        <p>{post.date}</p>
        <h2>{post.header.toUpperCase()}</h2>
        <img className="mobile-img" alt="Post img" src={post.img} />
        <div className="content">{post.content}</div>
      </div>
      <hr />
    </div>
  );
};

const WhatsNew = (props) => {
  const posts = [
    {
      header: "Toteutuneita tavoitteita!",
      date: "14.09.2023",
      content: (
        <>
          <p>
            Saimme tänään tiedon, että Suomen Kennelliitto on myöntänyt
            kasvatustyölleni cane corsojen parissa korkeimpana
            huomionosoituksena Lauri Vuolasvirta -palkinnon. Vuolasvirta
            -palkinnot jaetaan Helsinki Voittaja 2023 -näyttelyn yhteydessä
            Helsingin Messukeskuksessa lauantaina 16.12.2023. Nähdään siellä!
          </p>
          <p>
            Kiitän omasta puolestani kaikkia palkinnon saamiseen
            myötävaikuttaneita avunanto- ja yhteistyökumppaneita sekä
            läheisiäni. Iso kiitos kuuluu myös kasvattieni omistajille,
            haltijoille ja osa-omistajille sekä kasvattajakolleegoille omien
            kasvattieni taustalla.Tuntuu hienolta olla ensimmäinen cane corso
            kasvattaja, jolle tämä huomionosoitus myönnetään. Suomen
            Kennelliiton uutinen aiheesta:{" "}
            <a
              href="https://www.kennelliitto.fi/tietoa-meista/uutiset/kennelliitto-palkitsee-38-kennelnimen-ansioituneet-kasvattajat-lauri-vuolasvirta-palkinnolla-0"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.kennelliitto.fi/tietoa-meista/uutiset/kennelliitto-palkitsee-38-kennelnimen-ansioituneet-kasvattajat-lauri-vuolasvirta-palkinnolla-0
            </a>
            .
          </p>
          <p>
            Seuraavat pentueet tulevatkin sitten olemaan Vuolasvirta -palkitulta
            kasvattajalta. Aika hienoa. Elämme jännittäviä aikoja, sillä ensi
            vuoden alkupuolelle pyritään saamaan aikaiseksi parikin pentuetta,
            joista kotiin tavoitellaan muun muassa osallistujia Helsingin
            arvomittelöihin vuodelle 2025. Myös muutama jo viime vuosina
            syntynyt pentue on ollut tämän tavoitteen alla ja kun katselee
            tuloksiamme, niin pitkäjänteisesti tavoitteen suuntaan
            työskennellään.
          </p>
        </>
      ),
      img: require("../../assets/news/10/kuva1.jpg"),
    },
    {
      header: "Uusi C.I.E. ja uudet C.I.B.-J -anomukset FCI:lle.",
      date: "29.08.2023",
      content: (
        <>
          <p>
            Giantpaw's Murianin etuliitteet saivat eteensä Kansainvälisen
            valionarvon kirjaimet. Murianin tittelirivistö on jo melko komea ja
            voimmekin puhua kennelin uudesta multi championista. Murianin
            kohdalla saatoimme PEVISA-tutkimuksetkin loppuun ja ulkoa hieno mies
            on kaunis myös sisältä; HD B, ED 0, VA 0, SP 0, LTV 0 ja DSRA N/N.
          </p>
          <p>
            Pääsimme myös ensimmäistä kertaa anomaan Kansainvälisiä
            juniorimuotovalionarvoja lauman nuorimmaisille, Rastabanille sekä
            Señorita Por Favorille ja FCI vahvistanee tittelit loppuvuoden
            aikana. Huikeaa settiä junioreilta tällä kaudella.
          </p>
        </>
      ),
      img: require("../../assets/news/10/kuva2.jpg"),
    },
    {
      header: "Erikoisnäyttelystä menestystä 51 corson joukosta",
      date: "20.08.2023",
      content: (
        <>
          <p>
            Italialaisen tuomari Massimiliano Mannuccin kehässä 14kk ikäinen
            juniorimme Señorita Por Favor voitti luokassaan 13 juniorinarttua,
            kaikki 6 avoimen luokan narttua ja vielä 7 valioluokan narttua ollen
            ERI+SA+PN1+VSP-JUN+VSP ja saavutti viimeisellä juniorisertillään
            Suomen juniorimuotovalion arvon. Veteraanimme, 10-vuotias La
            Protezione Migliore Hannibal voitti luokkansa ollen rotunsa paras
            veteraani ja sijoittui myöhemmin veteraanien BIS-kehässä toiseksi.
            Pentuluokassa debytoi useampi viimeisemmän T-pentueemme yksilö ja
            rotunsa paras pentu oli Terminus, joka sijoittui myöhemmin huikeasti
            BIS-kehässä ensimmäiseksi. Pentuluokassa VSP niin ikään kasvattini
            Tyche. Päivän kruunasi kasvattajaluokasta ROP ja myöhemmin
            BIS1-sijoitus. Erikoisnäyttelyssä vietimme myös perinteikästä
            kasvattitapaamistamme.
          </p>
        </>
      ),
      img: require("../../assets/news/10/kuva3.jpg"),
    },
    {
      header: "Porin Kansainvälinen koiranäyttely",
      date: "30.07.2023",
      content: (
        <>
          <p>
            Kotikenttäkinkerit. Kroatialaisen tuomari Jasna Matejcic kehässä 10
            cane corsoa, joista kolmelle ERI+SA ja nämä olivat meidän koiria;
            Giantpaw's Rastaban PU1+ROP+JUN-ROP+JUN-CACIB
          </p>
          <p>
            Giantpaw's Señorita Por Favor
            PN1+VSP+JUN-VSP+SERT+JUN-SERT+JUN-CACIB ja näin ollen päästään
            anomaan Kansainvälisen juniorimuotovalion arvoa. Giantpaw's Ourania
            PN2+CACIB
          </p>
          <p>Lisäksi kasvattajaluokasta sijoitus rotunsa paras!</p>
        </>
      ),
      img: require("../../assets/news/10/kuva4.jpg"),
    },
    {
      header: "Juniorit Ruotsin retkellä.",
      date: "22.07.2023",
      content: (
        <>
          <p>
            Kesäloman alkajaisiksi suuntasimme viikonloppureissuun helteiseen
            Ruotsiin. Siellä osallistuimme Västeråsissa Kansainväliseen
            koiranäyttelyyn ja todella hienolla menestyksellä. Juniorit veivät
            ja valiot vikisivät.
          </p>
          <p>
            Giantpaw's Rastaban PU1+ROP+JUN-ROP+SERT+JUN-CACIB ja näin ollen
            päästään anomaan Kansainvälisen juniorimuotovalion arvoa.
          </p>
          <p>
            Giantpaw's Señorita Por Favor
            PN1+VSP+JUN-VSP+SERT+JUN-SERT+JUN-CACIB.
          </p>
        </>
      ),
      img: require("../../assets/news/10/kuva5.jpg"),
    },
    {
      header: "Hieno päätös juhannukselle!",
      date: "25.06.2023",
      content: (
        <>
          <p>
            Tänään Jyväskylän kansainvälisessä koiranäyttelyssä, tuomarina Mervi
            Raisaari. Giantpaw’s Ourania aka Impi 28kk; ERI, SA, PN1, SERTI,
            CACIB ja ROP tullen Suomen ja Viron muotovalioksi. Giantpaw’s
            Rastaban aka Jorma-Antero 14kk; ERI, SA, JUN SERT, JUN CACIB, JUN
            ROP, VSP tullen Suomen juniorimuotovalioksi.
          </p>
        </>
      ),
      img: require("../../assets/news/10/kuva6.jpg"),
    },
    {
      header: "Menestystä Viron Voittaja -näyttelystä.",
      date: "03.06.2023",
      content: (
        <>
          <p>
            Super hieno päivä romanialaisen Robert C. Bambach:n kehässä.
            Veteraanimme FI&EE Ch. FI&EE VCh. HeJW-14 TLNVW-21 EEVW-21&22
            HeVW-22 NORD VW-22 FIVW-22 La Protezione Migliore Hannibal teki
            hattutempun ja saavutti kolmannen Viron veteraanivoittajatittelin
            perättäisinä vuosina sekä kirjaimet EEVW-23 nimensä eteen.
          </p>
          <p>
            Junioriluokan uroksissa Giantpaw’s Rastaban ERI, SA, JUN-SERT,
            JUN-CACIB, ROP JUN, ROP ja kolme titteliä kertaheitolla: Viron
            juniorivoittaja -23, Viron Voittaja -23 sekä Viron
            juniorimuotovalio. Junioriluokan nartuissa Giantpaw’s Señorita Por
            Favor ERI, SA, JUN-SERT, JUN-CACIB, VSP JUN, PN2 ja Viron
            juniorivoittaja -23.
          </p>
          <p>
            Valioluokassa FI&EE Ch. Giantpaw’s Marabel ERI, SA, PN1, VSP, CACIB
            ja Viron Voittaja -23. Ja kasvattajaluokasta tiimille ROP-KASV.{" "}
          </p>
        </>
      ),
      img: require("../../assets/news/10/kuva7.jpg"),
    },
    {
      header: "Sateinen ulkonäyttelykauden avaus Harjavallassa!",
      date: "28.05.2023",
      content: (
        <>
          <p>
            Tuomarina niin rotukehässä kuin bis-kehissämme israelilainen Eytan
            Hendel. Tuomarin sanoin: ”There is dogs and then there is She” -
            Giantpaw’s Señorita Por Favor, joka sai bis-kehässä kuulutusten
            myötä tuomariteltankin heräämään, por favor. Pirjo 11kk JUN ERI, SA,
            JUN SERT, ROP JUN, PN1, SERT, ROP, BIS2 JUN.
          </p>
          <p>
            Giantpaw’s Rastaban 13kk JUN ERI, SA, JUN SERT, VSP JUN, PU1, SERT,
            VSP. Jorma-Anterolla takana kaksi Suomen näyttelyä ja sertitili heti
            odottamaan kahden vuoden ikää.
          </p>
          <p>Giantpaw’s kasvattajaluokassa KP+ROP KASV +BIS3 KASV.</p>
        </>
      ),
      img: require("../../assets/news/10/kuva8.jpg"),
    },
    {
      header: "Vuoden näyttelykehät käyntiin tästä!",
      date: "23.04.2023",
      content: (
        <>
          <p>
            Uusi kausi starttasi upeissa tunnelmissa Lahden Kansainvälisessä
            koiranäyttelyssä. Kuun alussa 12kk täyttänyt Rastaban debytoi Suomen
            kehissä ja kuinka valtavan rouheasti räkänokka pärjäsikään voittaen
            myös valiot. Jorma-Anteromme hienosti ERI, SA, JUN SERT, JUN ROP,
            JUN CACIB, SERT ja ROP. Team Giantpaw's kasvattajaluokassa myös ROP.
            Tästä hyvä noste tulevaan kauteen. Tuomarina italialainen
            rotuspesialisti Alberto Cuccillato.
          </p>
        </>
      ),
      img: require("../../assets/news/10/kuva9.jpg"),
    },
    {
      header: "Hyvää ystävänpäivää!",
      date: "14.02.2023",
      content: (
        <>
          <p>
            Meillä tätä ihanaa läheisyyden ja rakkauden päivää on vietetty
            T-pentueen (Maharadža x Lady Macbeth) syntymän merkeissä 🥰. Tuli
            täysi kymppi. Äitikoira ja pentuset voivat hyvin. Sukupuolijakauma 8
            urosta ja 2 narttua. Värijakauma puoliksi mustapohjaisia ja puoliksi
            harmaapohjaisia. Pentueeseen ei ole otettu etukäteisvarauksia,
            mikäli cane corso kuume on korkeana niin olethan rohkeasti
            yhteydessä.
          </p>
        </>
      ),
      img: require("../../assets/news/03/13.jpg"),
    },
    {
      header: "Vuoden Voitokkain Cane Corso 2022 -kilpailun tulokset",
      date: "18.01.2023",
      content: (
        <>
          <p>
            Rotujärjestö Bullmastiffit & Mastiffit ry:n Vuoden Voitokkain 2022
            kilpailun tulokset on julkaistu ja hyvin meni.
          </p>
          <p>💫 Vuoden Voitokkain kasvattaja #1 Giantpaw’s Cane Corso</p>
          <p>
            💫 Vuoden Voitokkain veteraani #1 ja Vuoden Voitokkain cane corso
            uros #2 La Protezione Migliore Hannibal
          </p>
          <p>💫 Vuoden Voitokkain cane corso uros #3 Giantpaw’s Murian</p>
          <p>
            💫 Vuoden Voitokkain cane corso narttu #4 Giantpaw’s Ourania, #5
            Giantpaw’s Lady Princess Una ja #6 Giantpaw’s Lady Macbeth.
          </p>
          <p>
            Kiitos ja kumarrus. Upeaa tulosta ei niin aktiiviselta kaudelta.
          </p>
        </>
      ),
      img: require("../../assets/news/03/12.jpg"),
    },
    {
      header: "Hurraa, pentuja tulossa!",
      date: "09.01.2023",
      content: (
        <>
          <p>
            Käytiin kurkistamassa miltä seuraavat kuukaudet ja kalenteri
            näyttää. Kiireitä lupailee. Tohtori pysäytti näytölle näinkin
            sopivan kuvan. Vitsikästä. Ystävänpäivän tienoilla odottelemme
            T-pentuetta (Giantpaw's Maharadža x Giantpaw's Lady Macbeth)
            syntyväksi.
          </p>
        </>
      ),
      img: require("../../assets/news/03/11.jpg"),
    },
    {
      header: "Nuoriso-osaston kanssa loppiaista viettämässä Tartossa.",
      date: "08.01.2023",
      content: (
        <>
          <p>
            Uuden vuoden näyttelykauden avaus sujui erinomaisesti Vironmaalla 2x
            CAC -näyttelyissä.Ensimmäinen päivä ja nuorison debyytti tuomari
            Goran Dojcinoski (Makedonia) kehässä. Pentuluokassa Giantpaw’s
            Señorita Por Favor KP+very promising ja ROP-pentu, junioriluokkaan
            juuri yltänyt Giantpaw's Rastaban ERI, SA, JUN-SERT; ROP-juniori ja
            VSP sekä nuorison lastenvahti Darling Z Gildii Nieustraszonych ERI,
            SA, SERT; ROP ja uusi Viron MVA. Vain pentu vietiin BIS-kehään,
            tuomari Mariano Di Chicco (Italia) sijoitti pennun BIS2. Toisena
            päivänä esiinnyttiin kasvattaja-tuomari Di Chiccolle, pentuluokassa
            Giantpaw’s Señorita Por Favor KP+very promising ja ROP-pentu,
            junioriluokassa Giantpaw's Rastaban ERI, SA, JUN-SERT, ROP-juniori,
            PU2 ja valioluokassa Darling Z Gildii Nieustraszonych ERI, SA, SERT,
            VSP. Jatkokehiin emme jälkimmäisenä päivänä pystyneet jäämään. Tästä
            on hieno ponnistaa uuteen kauteen!
          </p>
        </>
      ),
      img: require("../../assets/news/03/10.jpg"),
    },
    {
      header: "Pentuesuunnitelmia alkuvuoteen.",
      date: "12.12.2022",
      content: (
        <>
          <p>
            Tänään oli melkoisen kova lumimyräkkä. Täydellinen ilma tuulessa ja
            voimakkaassa lumituiskussa astuttaa Fi mva Giantpaw's Lady Macbeth.
            Mikäli tähdet ovat kohdallaan niin ystävänpäivän tienoilla saamme
            hedelmää yhden astutuksen taktiikasta. Jännittävää.
          </p>
        </>
      ),
      img: require("../../assets/news/03/9.jpg"),
    },
    {
      header:
        "Päätimme näyttelykautemme onnistumisiin viikonloppuna Helsingissä.",
      date: "5.12.2022",
      content: (
        <>
          <p>
            Veteraanimme, 9,5v La Protezione Migliore Hannibal teki hattutempun
            saavuttaen kaikista kolmesta arvonäyttelystä tittelin; Helsingin,
            Pohjoismaiden ja Suomen veteraanivoittaja -22. Sivussa vielä
            muotovalion arvot Suomeen ja Viroon sekä Suomen veteraanimuotovalion
            arvo. Sir Olavi siirtyi näiden näyttelyiden myötä viettämään
            leppoisia eläkepäiviä laumansa parissa.
          </p>
          <p>
            Viimeisimmän pentueemme Giantpaw’s Serafina ansiokkaasti
            pentunäyttelyistä koppasi Helsingin ja Suomen pentuvoittajan-22
            sijoitukset. Pennuille ei järjestetty mahdollisuutta osallistua
            Pohjoismaidenvoittajanäyttelyyn.
          </p>
          <p>
            Serafinan emä, Darling Z Gildii Nieustraszonych saavutti Suomen
            voittaja-22 tittelin.
          </p>
          <p>
            Kennel siirtyy talvimoodiin ja valmistautumaan ensi vuoteen. Hyvää
            loppuvuotta kaikille!
          </p>
        </>
      ),
      img: require("../../assets/news/03/8.jpg"),
    },
    {
      header: "Valio-uutisia",
      date: "07.11.2022",
      content: (
        <>
          <p>
            Erikoisnäyttelymme yhteydessä sijoituskoiramme Giantpaw's Lady
            Princess Una valioitui Suomeen. Sittemmin Unan
            PEVISA-terveystutkimukset saatettiin loppuun; ED 0/1, HD A/A, SP0,
            VA0, LTV0, DSRA N/N, silmät: terveet, sydän: terve.
          </p>
        </>
      ),
      img: require("../../assets/news/03/2.jpg"),
    },
    {
      header: "Kenneliin uusi multi-champion!!!",
      date: "06.11.2022",
      content: (
        <>
          <p>
            Sijoituskoiramme Giantpaw's Murian on käynyt harrastelemassa
            haltijansa Verna Aitto-ojan kanssa. Aslanille huikea tittelirivistö;
            FI MVA DK MVA SE MVA NO MVA EE MVA LV MVA LT MVA BALT MVA EEJMVA
            HeJW-21 VIV-22 - Aslan käväisi myös luustokuvilla, HD B/B, ED 0/0,
            selkä kuvataan myöhäisemmässä vaiheessa. Suuresti kiitoksia Vernalle
            aktiivisuudesta!
          </p>
        </>
      ),
      img: require("../../assets/news/03/7.jpg"),
    },
    {
      header: "Terveystutkimustuloksia Puolasta - vähän hienoo!",
      date: "20.10.2022",
      content: (
        <>
          <p>
            Ihana Impimme, Giantpaw's Ourania (La Protezione Migliore Hannibal x
            Giantpaw's Hazard High Life) on käynyt virallisilla luustokuvilla;
            HD A/B, ED 0/0, OCD terve, DSRA N/N. Jäämme odottamaan
            kansainvälistä sertifikaattia tuloksista.
          </p>
        </>
      ),
      img: require("../../assets/news/03/6.jpg"),
    },
    {
      header: "Valio-uutisia",
      date: "09.10.2022",
      content: (
        <>
          <p>
            Hämeenlinnasta kajahti, että sijoituskoiramme Giantpaw's Lady
            Macbeth on saavuttanut Suomen muotovalion arvon. Macbeth kävi
            taannoin myös PEVISA-terveystarkastuksissa; ED 0/0, HD B/B, SP0,
            VA0, LTV1(viimeinen lannenikama inasen lyhyempi), DSRA N/N.
          </p>
        </>
      ),
      img: require("../../assets/news/03/3.jpg"),
    },
    {
      header: "Erkkarista menestystä!",
      date: "29.08.2022",
      content: (
        <>
          <p>
            Vietimme mukavan viikonlopun rotujärjestön erikoisnäyttelyssä
            Iitissä.
          </p>
          <p>
            KP+ROP- ja BIS3 kasvattaja Giantpaw's - Tuomari Emil Munteanu Munćan
            päätti kasvattajaluokan arvostelun itselleni hyvin merkityksellisin
            sanoin, ”Congratulations to the breeder on high quality dogs” - otan
            nämä sanat nöyränä maailmanluokan kasvattajatuomarilta vastaan ja
            jatkan työtäni rakkaan harrastukseni parissa.
          </p>
          <p>KP+ROP- ja BIS3 jälkeläisryhmä Giantpaw’s Goljatt</p>
          <p>
            La Protezione Migliore Hannibal -VEK1 - ERI, SA, ROP-VET, PU2, SERT,
            VET-SERT, BIS-VET3 - ”9 years old good type of the head strong
            skull. Proportion skull on the limit. Correct insertion of ears
            carrying a bit in back. Correct position but shape of the eyes could
            be better. Correct nose with almost open nostrils, a bit empty eyes,
            correct bite, correct neck, chest, front. Correct angulations,
            upper, underline. Correct movement. Great condition for age.”
          </p>
          <p>
            Giantpaw’s Lady Princess Una -AVK1 - ERI, SA, PN2, SERT, FI MVA - ”3
            years old correct type of the head, correct proportion muzzle and
            skull, correct lines of the head. Correct insertion of the ears.
            Correct shape position and colour of the eyes. Correct nose with
            almost correct nostrils. Correct bite, level. A bit skin on the
            neck, correct neck, chest and front, angulations, correct upper,
            underline. Correct movement.”
          </p>
          <p>Juniori urokset</p>
          <ul>
            <li>Giantpaw’s Perfetto Come Te - EVA</li>
            <li>Giantpaw’s Primo Pavarotti - EH</li>
            <li>JUK3 Giantpaw’s Quantrill - EH</li>
            <li>JUK2 Giantpaw’s Quirinus - EH</li>
          </ul>
          <p>Nuoret urokset</p>
          <ul>
            <li>NUK1 Giantpaw’s Orpheus - EH</li>
          </ul>
          <p>Avoimen urokset</p>
          <ul>
            <li>
              AVK1 Giantpaw’s King of Aurora Borealis - ERI, SA, PU3, VARA-SERT
            </li>
            <li>Giantpaw’s Massimiliano - EH</li>
            <li>AVK3 Giantpaw’s Murian - ERI, SA</li>
          </ul>
          <p>Valioluokan urokset</p>
          <ul>
            <li>VAK2 Giantpaw’s Goljatt - ERI, SA</li>
          </ul>
          <p>Juniori nartut</p>
          <ul>
            <li>JUK1 Giantpaw’s Pippi - ERI, SA, JUN-VSP</li>
            <li>Giantpaw’s Quetzalli - EH</li>
            <li>JUK2 Giantpaw’s Quite Magic Scandal - ERI</li>
          </ul>
          <p>Nuoret nartut</p>
          <ul>
            <li>NUK1 Giantpaw’s Ourania - EH</li>
          </ul>
          <p>Avoimen nartut</p>
          <ul>
            <li>AVK2 Giantpaw’s Lady Macbeth - ERI, SA, VARA-SERT</li>
            <li>Giantpaw’s Mahala - EH</li>
            <li>AVK3 Giantpaw’s Marabel - EH</li>
          </ul>
          <p>Valioluokan nartut</p>
          <ul>
            <li>Giantpaw’s Gryscha - ERI</li>
            <li>VAK4 Giantpaw’s Hazard High Life - ERI, SA</li>
          </ul>
          <p>Jälkeläisryhmä</p>
          <ul>
            <li>JÄK2 La Protezione Migliore Hannibal - KP</li>
          </ul>
          <p>
            Kiitos järjestäjille, kiitos tuomarille, kiitos tiimilleni. Onnea
            myös muille voittajille ja menestyjille!
          </p>
        </>
      ),
      img: require("../../assets/news/03/5.jpg"),
    },
    {
      header: "Cane corson syvin olemus",
      date: "21.07.2022",
      content: (
        <>
          <p>
            S-pentueesta (Gondor Corso Du Munteanu x Darling Z Gildii
            Nieustraszonych) kotiin jäävä vahvistus, Giantpaw’s Señorita Por
            Favor näyttää mallia, mistä pienet viisiviikkoiset cane corsot on
            tehty.
          </p>
        </>
      ),
      img: require("../../assets/news/03/1.jpg"),
    },
    {
      header: "Sähköposti-asiaa!",
      date: "30.06.2022",
      content: (
        <>
          <p>
            Pahoittelemme, että sähköpostit eivät ole tavoittaneet meitä
            edellisen kuukauden aikana. Ehkä pidemmältäkin aikaa.
          </p>
          <p>
            Toivottavasti olemme saaneet nyt tarpeeksi tilaa vapautettua.
            Tarkoitus ei ole ollut olla töykeä, emme vain ole olleet tietoisia
            saapumattomista maileista.
          </p>
        </>
      ),
      img: require("../../assets/news/13.1.jpg"),
    },
    {
      header: "S-pentue on ilonamme ja upealta vaikuttaa!",
      date: "11.06.2022",
      content: (
        <>
          <p>
            Kevään astutusmatka Ruotsiin on poikinut hienon pentueen.
            Pentueeseen on syntynyt 1 uros ja 6 narttua. Pentuja ei ole vielä
            varattu, joten kaikilla on mahdollisuus vakuuttaa kasvattaja
            tarjoamalla parasta mahdollista kotia.
          </p>
        </>
      ),
      img: require("../../assets/news/juupso_3.jpg"),
    },
    {
      header: "Saammeko esitellä uusimman tulokkaamme?",
      date: "09.05.2022",
      content: (
        <>
          <p>
            Pitkän tauon jälkeen kotiamme asustaa tummanpuhuva raitapaita,
            virallisemmin Giantpaw's Rastaban. Joistakin pennuista sitä vain
            syntymähetkellä tietää, että se on tullut maailmaan iskeäkseen
            kasvattajaa suoraan sydämeen.
          </p>
        </>
      ),
      img: require("../../assets/news/juupso_2.jpg"),
    },
    {
      header: "Hulk Hoganin ja Hazardin R-pennut syntyivät Aprillipäivänä!",
      date: "01.05.2022",
      content: (
        <>
          <p>
            Kaikki ei mennyt kuukausi sitten ihan odotetulla tavalla. Puolet
            pennuista syntyivät kotona ja loput sektioitiin pahasti ja
            useammasta kohdasta repeytyneestä kohdusta. Pentueen emä taisteli
            lopulta hengestään eläinsairaalan teho-osastolla, tila oli hyvin
            kriittinen ja selviytyminen todella epävarmaa. Pennuille saatiin
            onneksi varaäiti, tornjak-rotuinen ”Tähti”. Varaäiti huolehtii
            pennuista kuin omistaan. Pentujen emä Ihme on toipunut ja toipuu
            hengenvaarallisesta verenvuotoanemiasta ja hoidoksi saamastaan mm.
            verensiirtosta hyvin. Pentue on varattu harrastaviin koteihin.
          </p>
        </>
      ),
      img: require("../../assets/news/juupso_1.jpg"),
    },
    {
      header: "Tiineysultrauutisia",
      date: "25.02.2022",
      content: (
        <>
          <p>
            Sota Ukrainassa on saanut tämän meille ison uutisen tuntumaan
            pieneltä, mutta haluamme jakaa tiedon sitä odottaville. Toinen
            alkuvuoden keinosiemennyksistä tuottaa tulosta ja odotamme
            yhdistelmästä Hulk Hogan Dell’Impero Maya x Giantpaw’s Hazard High
            Life pentuetta syntyväksi aprillipäivän tienoilla. Odotamme
            pentueeseen syntyväksi uusia näyttely- ja kilpakenttien tähtiä.
          </p>
        </>
      ),
      img: require("../../assets/news/new/Kuva2.jpg"),
    },
    {
      header: "Impin syntymäpäiväjuhlia",
      date: "19.01.2022",
      content: (
        <>
          <p>
            Ourania täyttää jo 12kk. Aika menee niin kovin nopeaan, juuri tuon
            syntymää kuumeisesti odotettiin. Tuohon raamiin massa seuraavina
            vuosina niin saattaa olla kohtuu vakuuttavan oloinen ilmestys.
            Näyttelyiden suhteen lomailemme kuitenkin pitkälti kesään asti.
          </p>
        </>
      ),
      img: require("../../assets/news/new/Kuva3.jpg"),
    },
    {
      header: "Vuoden voitokkain cane corso 2021 -kilpailun tulokset",
      date: "17.01.2022",
      content: (
        <>
          <p>
            Nartuissa voittoon ylti edesmennyt juniorimme EeJch TlnJw-21 TlnW-21
            EeJw-21 Giantpaw’s Mama Matama ja toisen sijan saavutti vuoden 2018
            ykkönen ja vuoden 2019 kolmonen Multi Ch. Giantpaw's Hazard High
            Life.
          </p>
          <p>
            Uroksissa toiselle sijalle sijoittui EeJch HeJw-21 Giantpaw's Murian
            ja veteraanimme HeJw-14 TlnVw-21 EeVw-21 La Protezione Migliore
            Hannibal hienosti kolmantena.
          </p>
          <p>
            Kasvattajakilpailussa sijoituimme viime vuosien ykkösen ja kolmosen
            väliin komiasti toiseksi. Rotujärjestön alaisten rotujen kaikki
            tulokset:{" "}
            <a
              href="https://www.mastiffit.com/vuoden-voitokkain-2021"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.mastiffit.com/vuoden-voitokkain-2021
            </a>
          </p>
        </>
      ),
      img: require("../../assets/news/new/Kuva4.jpg"),
    },
    {
      header: "Matami on myrkytetty, havaintoja kaivataan!!!",
      date: "11.10.2021",
      content: (
        <>
          <p>
            Kaivataan havaintoja poliisin tutkinnan tueksi Porin Lattomereltä.
            Tarkemmin Lattomerentien ja Hirvelänmäentien ja niiden läheisyydestä
            viime viikon torstaina, 7.10.2021 klo 18.45 aikoihin ja ajan
            ympärillä liikkuneesta, vaaleaan hupulliseen takkiin/puseroon
            pukeutuneesta, aikuismitoissa olevasta henkilöstä jo olemassa olevan
            silminnäkijähavainnon tueksi.
          </p>
          <p>
            Myös muunlaiset, asiaan mahdollisesti liittyvät vihjeet huomioidaan.
            Luvataan mittava rahallinen palkkio koiran kuolemaan johtaneessa
            myrkytys tapauksessa ratkaisevan vihjeen antajalle.
          </p>
          <p>
            Vihjeet pyydetään kirjallisena yhteystietoineen ja otsikoksi
            tutkintailmoituksen numero 5650/R/40724/21 joko suoraan poliisille{" "}
            <a href="mailto:rek.turku@poliisi.fi">rek.turku@poliisi.fi</a> tai{" "}
            <a href="mailto:satu.porsti@gmail.com">satu.porsti@gmail.com</a>.
          </p>
          <p>
            <a
              href="https://www.koiramme.fi/23-artikkelit/juttu/uutinen/1211-koira-myrkytetty-porissa"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.koiramme.fi/23-artikkelit/juttu/uutinen/1211-koira-myrkytetty-porissa
            </a>
          </p>
        </>
      ),
      img: require("../../assets/news/new/11.jpg"),
    },
    {
      header: "Terveiset WDS:stä, Maailman Voittaja -näyttelystä Brnosta",
      date: "02.10.2021",
      content: (
        <>
          <p>
            Osallistuttiin tänään Tsekkien Brnossa maailmanvoittaja -näyttelyyn.
            Impi aka Giantpaw's Ourania (Multi ch. Giantpaw's Hazard High Life x
            HeJW-14 Tln VW-21 EeVW-21 La Protezione Migliore Hannibal) 8kk
            pärjäsi hienosti pentuluokassa ollen cane corsojen maailman
            neljänneksi kaunein narttupentu, 4° World Winner Puppy 2021.
            Tuomarina Bruno Nodalli, puppy class, very promising, placement 4.{" "}
          </p>
          <p>
            Juniorimme Ee jmva & Ee JV-21 & Tln JV-21 & Tln V-21 Giantpaw's Mama
            Matama aka Matami (Amina Award For Me x Fi&Ee Ch. Giantpaw's
            Goljatt) upeasti kuuden parhaan joukossa luokassaan.
          </p>
        </>
      ),
      img: require("../../assets/news/new/1.jpg"),
    },
    {
      header: "Q-pentue on syntynyt!",
      date: "21.09.2021",
      content: (
        <>
          <p>
            Sadonkorjuun täysikuun aikaan odotettu Q-pentueemme syntyi. Karma
            pyöräytti tehokkaalla tahdilla maailmaan kolme tyttöä ja kuusi
            poikaa. Yhdistelmällä haetaan uutta pistoa jalostustyöllemme ja
            pentue tulee varattavaksi myöhemmin.
          </p>
        </>
      ),
      img: require("../../assets/news/new/2.jpg"),
    },
    {
      header: "Uusi Suomen ja Viron muotovalio",
      date: "18.09.2021",
      content: (
        <>
          <p>
            Giantpaw's Gryscha aka Riesa kävi pokkaamassa Hyvinkään kaikkien
            koirarotujen näyttelyssä unkarilaiselta tuomari Lásló Erdokselta
            ROP:n ja viimeisen sertinsä. Riesa valioitui näin kahden maan
            muotovalioksi.
          </p>
        </>
      ),
      img: require("../../assets/news/new/3.jpg"),
    },
    {
      header: "Viron Voittajanäyttelystä titteleitä",
      date: "12.09.2021",
      content: (
        <>
          <p>
            Näyttelyviikonloppumme huipentui Viron Voittaja -näyttelyyn, josta
            tittelit jokaiselle osallistujallemme. Giantpaw's Hazard High Life
            EeW-21, juniorimme Giantpaw's Mama Matama EeJW-21 ja veteraanimme La
            Protezione Migliore Hannibal EeVW-21.
          </p>
        </>
      ),
      img: require("../../assets/news/new/4.jpg"),
    },
    {
      header: "Matami rocks!",
      date: "10.09.2021",
      content: (
        <>
          <p>
            Giantpaw's Mama Matamalle huikea saalis Tallinnan
            Voittajanäyttelyistä. Juniori nappasi niin Tln W-21 kuin Tln JW-21
            tittelit. Veteraanimme La Protezione Migliore ansaitsi tittelin Tln
            VW-21.
          </p>
        </>
      ),
      img: require("../../assets/news/new/5.jpg"),
    },
    {
      header: "Kukas se sieltä kurkistaa?",
      date: "10.09.2021",
      content: (
        <>
          <p>
            Kenneliin tänään cacibin myötä ensimmäinen INTERNATIONAL SHOW
            CHAMPION (C.I.E) kun NORD CH., FI CH., SE CH, NO CH., EE JCH, LV
            JCH, FI JW-17, TLNJW-18, EEJW-18, LVJW-18 Giantpaw's Hazard High
            Life aka Ihme Luigen KV-näyttelyssä tuomari Svein Bjarne Helgesenin
            arvioitavana. "EXC, CQ, BF1, CACIB, BOS - Excellent type & balance,
            excellent head & expression, excellent neck & topline, very good
            chest & body, well angulated, powerful & stabile in movement."
            Lähetimme C.I.E -anomuksen FCI:lle hyväksyttäväksi.
          </p>
        </>
      ),
      img: require("../../assets/news/new/6.jpg"),
    },
    {
      header: "2 x erikoisnäyttely!",
      date: "24.08.2021",
      content: (
        <>
          <p>
            Osallistuimme viime viikonloppuna kahteen erikoisnäyttelyyn
            suhteellisen mukavalla menestyksellä.
          </p>
          <p>
            Lauantaina 21.8.2021 järjestettiin rotujärjestömme, Bullmastiffit &
            mastiffit ry:n erikoisnäyttely Mustialassa. Tuomariksi oli kutsuttu
            italialainen Della Porta Dipinta -kennelin kasvattaja ja FCI-tuomari
            Mariano Di Chicco.
          </p>
          <p>Kennelin menestyjät:</p>
          <p>🏆 ERI, SA, AVK1, PN1, SERTI, VSP Giantpaw’s Lady Princess Una</p>
          <p>
            🏆 ERI, SA, VEK1, PU3, VASERT, ROP vet + BIS3 vet La Protezione
            Migliore Hannibal
          </p>
          <p>🏆 ROP + BIS2 kasvattaja Giantpaw’s</p>
          <p>🏆 KP, PEK1, VSP Giantpaw’s Omotose</p>
          <p>Muita tuloksia:</p>
          <p>👍 ERI, SA, VAK2 Giantpaw’s Hazard High Life</p>
          <p>👍 ERI, SA, AVK4 Giantpaw’s Gryscha</p>
          <p>👍 ERI, AVK2 Giantpaw’s King of Aurora Borealis</p>
          <p>👍 ERI, VAK3 Giantpaw’s Goljatt ja jälkeläisluokka KP + JÄK3</p>
          <p>👍 EH, JUK3 Giantpaw’s Marcella</p>
          <p>👍 EH, JUK4 Giantpaw’s Mahala</p>
          <p>👍 EH, JUK4 Giantpaw’s Massimiliano</p>
          <p>👍 EH Giantpaw’s Murian</p>
          <p>👍 EH Giantpaw’s Mama Matama</p>
          <p>👍 PEK1 Giantpaw’s Ourania</p>
          <p>👍 PEK2 Giantpaw’s Orpheus</p>
          <p>👍 PEK3 Giantpaw’s Odysseus</p>
          <p>
            Lady Princess Unan arvostelu: ”Excellent head, stop should be
            stronger. Excellent expression nice muzzle. Excellent balance of the
            body, excellent neck, good shoulder. Correct croup. Nice movement.”
          </p>
          <p>
            La Protezione Migliore Hannibal arvostelu: Excellent size and bone.
            Correct head & correct proportion and profile, stop could be
            stronger. Excellent fore chest. Could length of leg. Topline could
            be straighter, also angulation on rear. Perfect condition for age.”
          </p>
          <p>
            Kasvattajaryhmän (Goljatt, Gryscha, Lady Princess Una ja
            Massimiliano) arvostelu: ”All have correct heads good size and good
            bone, typical and nice temperament. Good movement, and correct
            construction of the body.”
          </p>
          <p>
            Osa koiristamme matkasi lauantain ja sunnuntain välisenä yönä
            Viroon, jossa osallistuimme 22.8.2021 Luigessa Viron Mastiffi Clubin
            järjestämään erikoisnäyttelyyn. Tuomarina latvialainen Vija
            Klučniece.
          </p>
          <p>Kennelin menestyjät:</p>
          <p>
            🏆 ERI, SA, VAK1, PU1, VSP, VSP-BIS4 Giantpaw’s Goljatt ja
            jälkeläisluokka KP, JÄK1 + BIS1
          </p>
          <p>
            🏆 ERI, SA, ROP JUN, JSERTI, PU2, JUN BIS3 saaden Viron JMVA-arvon
            Giantpaw’s Murian
          </p>
          <p>
            🏆 ERI, SA, VSP JUN, JSERTI, PN2 saaden Viron JMVA-arvon Giantpaw’s
            Mama Matama
          </p>
          <p>🏆 ERI, SA, AVK1, PN3, SERTI Giantpaw’s Gryscha</p>
          <p>🏆 ROP + BIS1 kasvattaja Giantpaw’s</p>
          <p>🏆 KP, PEK1, VSP Giantpaw’s Primo Pavarotti</p>
          <p>Muita tuloksia:</p>
          <p>👍 ERI, AVK2 Giantpaw’s Lady Princess Una</p>
          <p>👍 PEK1 Giantpaw’s Ourania</p>
          <p>
            Onnittelemme myös muita näyttelyissä menestyneitä. Ensi vuonna
            uudestaan.
          </p>
        </>
      ),
      img: require("../../assets/news/new/7.jpg"),
    },
    {
      header: "Puolan astutusreissu tuottaa tulosta",
      date: "18.08.2021",
      content: (
        <>
          <p>
            Karman pyöristyminen on ollut havaittavissa, mutta kävimme
            varmentamassa tiineyden eläinlääkärissä ultralla. Isoa pentuetta
            odotetaan syntyväksi syyskuun täydenkuun aikoihin.
          </p>
        </>
      ),
      img: require("../../assets/news/new/8.jpg"),
    },
    {
      header: "Kotikylän kinkereissä edustamassa!",
      date: "01.08.2021",
      content: (
        <>
          <p>
            Lähdimme Sir Olavin veteraani-iän täyttymisen syntymäpäivänä
            piipahtamaan Porin KV-näyttelyssä. Hienostihan se meni. Saatiin ROP,
            VSP, CACIB-UROS, CACIB-NARTTU, SERTI-NARTTU, ROP-VETERAANI JA
            ROP-KASVATTAJA.
          </p>
        </>
      ),
      img: require("../../assets/news/new/9.jpg"),
    },
    {
      header: "Käväistiin Oulussa",
      date: "11.07.2021",
      content: (
        <>
          <p>
            Kannatti lähteä. Karma aloitti matkalla juoksut, joten astutusmatka
            Puolaan lähiaikoina tiedossa. Oulun Nord -näyttelystä Karmalle
            valioiva serti.
          </p>
        </>
      ),
      img: require("../../assets/news/new/10.jpg"),
    },
    {
      header: "UUTISIA ENGLANNIKSI",
      date: "04.06.2021",
      content: (
        <>
          <p>
            We have been in front of big things and decisions during last year.
            Earlier we have already published our next litterplan in
            co-orporation with breeders of our Polish import and now, we have
            more big news to tell and something exciting to wait in next years.
          </p>
          <p>
            After long way of searching, thinking and talking we have reached
            finally a stage during this spring where something concrete is
            slowly starting to happen. Everything is already confirmed from our
            Kennel Club and tanks will arrive in Finland during this summer.
          </p>
          <p>
            To take our breeding work forward, we will have access to the seeds
            of these two wonderful males from Italy.
          </p>
          <p style={{ marginTop: 20 }}>Multi Ch. Ludovico Dell’Impero Maya</p>
          <p style={{ marginTop: 0 }}>
            (Ch. Producer Oregon Degli Elmi x Maya)
          </p>
          <p style={{ marginTop: 0 }}>HD-A ED-0</p>
          <p style={{ marginTop: 0 }}>Italian Champion</p>
          <p style={{ marginTop: 0 }}>Campione Sociale 2016</p>
          <p style={{ marginTop: 0 }}>Top Dog 2018</p>
          <p style={{ marginTop: 0 }}>Player Selected</p>
          <p style={{ marginTop: 0 }}>Cal 2</p>
          <p style={{ marginTop: 20 }}>Multi Ch. Hulk Hogan Dell’Impero Maya</p>
          <p style={{ marginTop: 0 }}>
            (Ch. Producer Oregon Degli Elmi x Chanel Dell’Impero Maya)
          </p>
          <p style={{ marginTop: 0 }}>HD-A ED-0</p>
          <p style={{ marginTop: 0 }}>Vice World Champion Milan 2015</p>
          <p style={{ marginTop: 0 }}>Italian Champion</p>
          <p style={{ marginTop: 0 }}>Campione Sociale 2016</p>
          <p style={{ marginTop: 0 }}>San Marino Champion</p>
          <p style={{ marginTop: 0 }}>Champion Foreign</p>
          <p style={{ marginTop: 0 }}>Young Promise Enci</p>
          <p style={{ marginTop: 0 }}>Young Champion San Marino</p>
          <p style={{ marginTop: 0 }}>Player Selected</p>
          <p style={{ marginTop: 0 }}>Cal 2</p>
          <p>
            I can't thank you enough Andrea Corbara and Morena Mazzotti from
            Dell’Impero Maya.
          </p>
        </>
      ),
      img: require("../../assets/news/040621.jpeg"),
    },
    {
      header: "JALOSTUSMATERIAALIA!",
      date: "20.05.2021",
      content: (
        <p>
          Giantpaw’s Lady Princess Una ”Martta” on terveystarkastettu PEVISA:n
          osalta hienoin tuloksin, HD A ED 1/0 silmät:terveet. Martalle
          suunnitellaan pentuetta vuoden sisään.
        </p>
      ),
      img: require("../../assets/news/200521.jpeg"),
    },
    {
      header: "RUUTI KÄVI LUUSTOKUVILLA!",
      date: "26.03.2021",
      content: (
        <p>
          Pieni, mutta pippurinen ja luustoltaan priima. Giantpaw’s Lady La
          Miracle korkkasi L-pentueen luustokuvaukset, HD B/A ED 0 - saamme
          alkaa etsimään Ruutille sulhasta. Haluamme kiittää Ruutin sponsoria
          Tiina Hernesniemeä tähän astisesta yhteistyöstä.
        </p>
      ),
      img: require("../../assets/news/260321.jpeg"),
    },
    {
      header: "VAHVISTUKSIA O-PENTUEESTA KENNELIIN",
      date: "14.03.2021",
      content: (
        <>
          <p>
            Olemme olleet vaikeiden valintojen edessä, mutta lopulta kotiin
            valikoitui kasvamaan Giantpaw’s Ourania. Saanen esitellä, Impi.
            Pikku riiviön kasvua ja kehitystä voit seurata{" "}
            <a
              href="https://www.facebook.com/media/set/?set=a.453066669122930&type=3"
              target="_blank"
              rel="noopener noreferrer"
            >
              täältä.
            </a>
          </p>
        </>
      ),
      img: require("../../assets/news/140321.jpeg"),
    },
    {
      header: "O-pentue on syntynyt!",
      date: "19.01.2021",
      content: (
        <>
          <p>
            Ihmeen ja Sir Olavin lapset ovat kokeneet luonnollisen syntymän. Emä
            ja 11 pentua (9 urosta ja 2 narttua) voivat oikein hyvin. Viisi
            pennuista on mustapohjaisia ja kuusi harmaapohjaisia. Pentuja on
            varattavissa ensisijaisesti harrastaviin ja näyttelyistä
            kiinnostuneisiin koteihin. Pentueen kuvia pääset kurkistamaan{" "}
            <a
              href="https://www.facebook.com/media/set/?set=a.401603944269203&type=3"
              target="_blank"
              rel="noopener noreferrer"
            >
              täältä.
            </a>
          </p>
        </>
      ),
      img: require("../../assets/news/o-pentue.jpeg"),
    },
    {
      header: "Matamin kuulumisia",
      date: "15.01.2021",
      content: (
        <>
          <p>
            Viime vuoden pentueesta (Amina x Goljatt) kotiin kasvamaan jäänyt
            Matami kasvaa ja kehittyy upeaksi koiran aluksi. Odotamme kovin,
            että koronatilanne sallisi lähdön näyttelyjourneylle. Koronan ja
            rajoitusten jyllätessä keskitymme harjoittelemaan tulevaisuuden
            koitoksia varten.
          </p>
        </>
      ),
      img: require("../../assets/news/mama.jpg"),
    },
    {
      header: "Astutusterveisiä!",
      date: "19.11.2020",
      content: (
        <>
          <p>
            Multi ch. Giantpaw’s Hazard High Life aka "Ihme" on astutettu
            HeJW-14 La Protezione Migliore Hannibal aka "Sir Olavilla".
            O-pentuetta odotetaan syntyväksi tammikuussa viikolla 3/21.
            Alustavia varauksia on mahdollista tehdä, toivomme tutustumaan
            käytäntöihimme pentuinfo välilehdellä.
          </p>
        </>
      ),
      img: require("../../assets/dogs/new/6.jpg"),
    },
    {
      header: "Toivo on kova äijä.",
      date: "31.10.2020",
      content: (
        <>
          <p>
            Giantpaw's His Infernal Majesty aka "Toivo" kävi luonnetestissä
            saaden hirmuisen hyvät pisteet, 223/300p. Toivo on kaikinpuolin
            hyvin hieno mies.
          </p>
        </>
      ),
      img: require("../../assets/dogs/new/5.jpg"),
    },
    {
      header: "Terveys- ja käyttäytymiskyselyistä",
      date: "28.10.2020",
      content: (
        <>
          <p>
            Cane corsojen jalostustoimikunnan puheenjohtajana haastan kaikki
            suomalaiset cane corsojen omistajat vastaamaan rodun terveys- ja
            käyttäytymiskyselyihin. Kyselyt tuottaa rotujärjestö Bullmastiffit &
            mastiffit ry / cane corsojen jalostustoimikunta:{" "}
            <a
              href="https://www.mastiffit.com/cane-corso"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.mastiffit.com/cane-corso
            </a>{" "}
            ja kyselyt toimivat muun muassa parhaillaan päivitystyön alla olevan
            jalostuksentavoiteohjelman materiaalina.
          </p>
          <p>
            Terveyskysely on sähköisessä muodossa tämän linkin takana:{" "}
            <a
              href="https://docs.google.com/forms/d/1-juDBiJzZkrt1bLHKz62OxTIp9IZlwZ7hwY5fADp9mA/viewform?chromeless=1&edit_requested=true&fbclid=IwAR1bwUROFt2UyjLC64OfIrGFv_34WXEKqmpBIzAzFq2N6-p06fq-4LK8heg"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://docs.google.com/forms/d/1-juDBiJzZkrt1bLHKz62OxTIp9IZlwZ7hwY5fADp9mA/viewform?chromeless=1&edit_requested=true&fbclid=IwAR1bwUROFt2UyjLC64OfIrGFv_34WXEKqmpBIzAzFq2N6-p06fq-4LK8heg
            </a>
          </p>
          <p>
            Luonnekysely sähköisenä täältä:{" "}
            <a
              href="https://docs.google.com/forms/d/1ldKjyyit0FEHlL2olKX3bPWMsRPO0wvO4B2sFRHOJyY/viewform?chromeless=1&edit_requested=true&fbclid=IwAR2C-HDQa-2E0dMqVnmfzgSkSoZu5DMox0QvZqUkvJK8nq0XWNC7tHwJDk4"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://docs.google.com/forms/d/1ldKjyyit0FEHlL2olKX3bPWMsRPO0wvO4B2sFRHOJyY/viewform?chromeless=1&edit_requested=true&fbclid=IwAR2C-HDQa-2E0dMqVnmfzgSkSoZu5DMox0QvZqUkvJK8nq0XWNC7tHwJDk4
            </a>
          </p>
        </>
      ),
      img: require("../../assets/dogs/new/2.1.jpg"),
    },
    {
      header: "Jalostusvalintoja!",
      date: "25.10.2020",
      content: (
        <>
          <p>
            Jokaisella pitäisi olla oma kuningas, eikö? Meillä kotona asuu
            kuninkaiden kuningas, iso musta, Sir Olavi, jolla voisi sanoa olevan
            erinomaista jälkeläisnäyttöäkin. Olemme tulleet kaiken selvitys- ja
            taustalla vaikuttavan jalostustyön jälkeen loppupäätelmään, että
            kuninkaiden kuninkaan tulee vielä pappa-iässä saada pentueellinen
            uusia kuninkaita ja kuningattaria kasvamaan. Sir Olavin
            jalostuskäyttö tulee ajankohtaiseksi vielä tänä vuonna ja
            morsiameksi on valikoitunut oma multivaliomme Ihme.
          </p>
        </>
      ),
      img: require("../../assets/dogs/new/4.jpg"),
    },
    {
      header: "M-pentueen polveutumistulokset ovat valmistuneet!",
      date: "22.10.2020",
      content: (
        <>
          <p>
            Voimme iloiten kertoa, että Laboklin lähestyi meitä tänään vain
            kolmen viikon odottamisen jälkeen dna-testituloksilla ja koko
            pentueen isäksi on varmennettu Giantpaw's Goljatt alias Kuningas
            Uuno.
          </p>
          <p>
            Olemme tyytyväisiä luontoäidin tahtoon, vaikka olisihan se ollut
            hienoa saada samaan pentueeseen kahden uroksen jälkeläisiä. Ehkä
            koitamme kaksoisastutusta tulevaisuudessa jossain yhdistelmässä
            uudelleen, mutta polveutumisen virallinen määrittäminen kuuluu tästä
            eteenpäin jokaiseen pentueeseemme.
          </p>
        </>
      ),
      img: require("../../assets/dogs/new/3.1.jpg"),
    },
    {
      header: "Vahvistuksia M-pentueesta kenneliin",
      date: "4.10.2020",
      content: (
        <>
          <p>
            Edelleen jännitämme, että mitenkä kaksoisastutuksen polveutumiset
            tulevat menemään, mutta kenneliin on pentueesta valikoitunut kaksi
            tulevaisuuden lupausta. Saanen esitellä, Giantpaw's Murian ja Mama
            Matama (5vko).
          </p>
        </>
      ),
      img: require("../../assets/dogs/new/1.1.jpg"),
    }, // new
    {
      header: "Syntyvän N-pentueen kuulumisia.",
      date: "2.9.2020",
      content: (
        <>
          <p>
            Riesa on käynyt tiineysröntgenissä. Sieltä paljastui yllätyksenä,
            että pentueeseen onkin viikon sisään syntymässä vain kaksi pentua.
            Näin ollen Riesa ei tuo kovinkaan paljoa helpotusta
            pentukuumeisille, mutta nämä kaksi tulevat olemaan yltiörakastettuja
            ja hyvin syöneitä. Ajatuksena tästä pentueesta on ollut jättää
            itselle niin uros kuin narttu, narttu kotiin ja uros
            yhteistyökotiin. Pentueeseen varauksia tehneisiin tulen sitten
            pentujen syntymän jälkeen olemaan yhteyksissä, mutta useampi jää nyt
            tästä N-pentueesta ilman omaa pentua. Pentue on kokonaisuudessaan
            varattu, #giantpawsnlitter
          </p>
        </>
      ),
      img: require("../../assets/news/3-3.jpg"),
    },
    {
      header: "M-PENTUE ON SYNTYNYT!",
      date: "25.8.2020",
      content: (
        <>
          <p>
            Giantpaw’s M-pentue on kokenut luonnollisen syntymän. Emä Amina
            Award For Me aka Usva ja elinvoimaiset pennut voivat hyvin.
            Pentueeseen on syntynyt 12 pentua; 5 narttua ja 7-1 urosta, kymmenen
            mustapohjaista ja kaksi harmaapohjaista. Valitettavasti viimeisenä
            tullut uros syntyi menehtyneenä. Pentue on kokonaisuudessaan
            varattu. #giantpawsmlitter
          </p>
        </>
      ),
      img: require("../../assets/news/2-3.jpg"),
    },
    {
      header: "Tuloksia rotujärjestö BMM:n erikoisnäyttelystä!",
      date: "15.8.2020",
      content: (
        <>
          <p>
            Vietimme iloisen onnistuneen ja aurinkoisen kasvattipäivän vuoden
            päänäyttelyssä saavuttaen kennelille loistavia tuloksia hyvin
            arvosteluin.
          </p>
          <ul>
            <li>
              ERI1, SA, PU1, ROP, SERT, BIS5 Giantpaw’s His Infernal Majesty
              (AVO)
            </li>
            <li>
              ERI1, SA, PN4, ROP-veteraani, BIS3-veteraani Multi Ch. Giantpaw's
              Big Mama (VET)
            </li>
            <li>ERI1, SA, PN2 Multi Ch. Giantpaw’s Hazard High Life (VAL)</li>
            <li>ERI2, SA, PU3, VA-SERT Giantpaw’s Jack of Hearts (AVO)</li>
            <li>ROP + BIS3 kasvattaja Giantpaw’s</li>
            <li>ROP + BIS2 jälkeläisluokka Fi&Ee Ch. Giantpaw’s Goljatt</li>
          </ul>
          <p>Kennelin muiden koirien saavutukset:</p>
          <ul>
            <li>EH2 Giantpaw’s King of Aurora Borealis (NUO)</li>
            <li>ERI1, SA Giantpaw’s King of Polar Night (NUO)</li>
            <li>ERI4 Fi&Ee Ch. Giantpaw’s Goljatt (VAL)</li>
            <li>ERI4 Giantpaw’s Lady Princess Una (JUN)</li>
            <li>EH Giantpaw’s Lady Macbeth (JUN)</li>
            <li>ERI Giantpaw’s Lady La Miracle (JUN)</li>
            <li>ERI2, SA Darling Z Gildii Nieustrasconych (AVO)</li>
          </ul>
          <p>
            Poissa Giantpaw’s Lord of The Rings (JUN) ja näin jälkeläisluokkaa
            Giantpaw’s Hazard High Life ei esitetty.
          </p>
          <p>
            Kiitämme järjestäjiä, tiimiä, kasvatin omistajia ja apukäsiä. Vuosi
            mennään taas voittajina. Onnittelemme myös muita näyttelyssä
            menestyneitä.
          </p>
          <p>Näyttelyn yhteydessä palkittiin myös Vuoden Voitokkaimmat 2019:</p>
          <ul>
            <li>
              Vuoden Voitokkain veteraani #1 Multi Ch. Giantpaw’s Big Mama
            </li>
            <li>
              Vuoden Voitokkain cane corso narttu #1 Multi Ch. Giantpaw’s Big
              Mama
            </li>
            <li>
              Vuoden Voitokkain cane corso narttu #3 Multi Ch. Giantpaw’s Hazard
              High Life
            </li>
            <li>
              Vuoden Voitokkain cane corso uros #2 Fi&Ee Ch. Giantpaw’s Goljatt
            </li>
          </ul>
          <p>Kuvat: Piia Säteri & Dogxpress</p>
        </>
      ),
      img: require("../../assets/news/1-3.jpg"),
    },
    {
      header: "Iloiset uutiset jatkuvat.",
      date: "10.8.2020",
      content: (
        <>
          <p>
            Riesa on ultrattu tiineäksi. Jäämme jännityksellä odottamaan
            pienilukuisemman N-pentueen syntymää. Kiitämme kaikkia tähän
            astisesta kiinnostuksesta syksyn pentueitamme kohtaan, molemmat
            pentueet ovat pitkälti varattuja. Ilmoittelemme mahdollisesti
            vapaista pennuista niiden synnyttyä. Varausjonoon on mahdollista
            hivuttautua tulevienkin pentueiden kohdalla. Muistathan tutustua
            pentuinfoomme.
          </p>
        </>
      ),
      img: require("../../assets/news/10-8.jpeg"),
    },
    {
      header: "Usvan tiineys todennettu!",
      date: "30.7.2020",
      content: (
        <>
          <p>
            Juhannuksen jälkeinen astutus tuottaa tulosta. Usva on tukevasti
            tiineänä. Malttamattomina jäämme odottamaan, että mitä sieltä
            kuukauden kuluttua M-pentueeseemme syntyy. Vanhempien dna-profiilit
            ovat valmistuneet, joten nyt vain jännitetään kumman isäkandidaatin
            pentuja sieltä saadaan.
          </p>
        </>
      ),
      img: require("../../assets/news/30-7.jpg"),
    },
    {
      header: "Terveisiä!",
      date: "7.7.2020",
      content: (
        <>
          <p>
            Olemme parhaillaan astutusmatkalla tapaamassa herrasmies Bumeria. Se
            oli rakkautta ensisilmäyksellä! Pentuetta odotetaan syntyväksi
            syyskuun puoleen väliin mennessä.
          </p>
        </>
      ),
      img: require("../../assets/news/1-2.jpg"),
    },
    {
      header: "Jalostusrintamalla uusi aikakausi",
      date: "2.7.2020",
      content: (
        <>
          <p>
            Jokaisella koiralla on yksilöllinen geneettinen “DNA-sormenjälki”,
            joka muodostuu vanhemmilta peritystä geneettisestä materiaalista.
            Tämä koiran DNA-tunniste voidaan määrittää erillisellä DNA-testillä.
            DNA-tunnisteen vertaaminen koiran oletettujen vanhempien vastaavaan
            tunnisteeseen mahdollistaa mm. pentujen polveutumisen todentamisen.
          </p>
          <p>
            Aloiteltiin sillä, että Suomen Kennelliiton hyväksymä
            DNA-näytteenottaja ja kennelneuvoja kävi ottamassa dna-näytteet
            Riesasta, Usvasta, Kuningas Uunosta ja Kyösti-Armaasta. He saavat
            viralliset DNA-tunnisteet, joita hyödynnetään tulevien pentueiden ja
            pentujen polveutumisen todentamiseen.
          </p>
          <p>
            Näin ollen näyttää siltä, että tästä eteenpäin Giantpaw's pennut
            DNA-testataan. Se on mielestämme eräänlainen takuu siitä, että pentu
            on lähtökohdiltaan aito.
          </p>
        </>
      ),
      img: require("../../assets/news/3-2.jpg"),
    },
    {
      header: "Lisää säpinää",
      date: "26.6.2020",
      content: (
        <>
          <p>
            Iloksemme myös Riesa ilmaisee juoksujensa alkamisella etuajassa,
            että kasvattajalle povataan kiireistä loppukesää kahden pentueen
            parissa. Riesa tullaan astuttamaan heinäkuun toisen viikon alkaessa
            ja pentuja odotetaan syntyväksi syyskuun puoleen väliin mennessä.
            Pentueesta ollaan oltu kivasti kiinnostuneita ja se on hyvää vauhtia
            täyttymässä ennakkovarauksista. Jos siis kiinnostusta sisälläsi
            panttaat, niin älä kovin kauaa.
          </p>
        </>
      ),
      img: require("../../assets/news/5-1.jpg"),
    },
    {
      header: "Juhannuksen taikaa!",
      date: "20.6.2020",
      content: (
        <>
          <p>
            Usvan juoksut ovat alkaneet ja ensi viikolla ajankohtaiseksi tulee
            kesäheilojen tapaaminen. Kyllä, luit aivan oikein. Heilojen,
            monikossa. Olen päätynyt yrittämään kaksoisastutuksen tuomia
            mahdollisuuksia ja Kyösti-Armaan rinnalle toiseksi isäkandidaatiksi
            on harkinnan jälkeen valikoitunut Kuningas Uuno. Pentue tulee
            kantamaan nimeä doubleM ja jännityksellä tulemme odottamaan miten
            luontoäiti ratkaisee pentujen määrän lisäksi polveutumisen. Tuleeko
            pentuja yhdelle vai kahdelle urokselle, isälle vai pojalle, vai
            ollenkaan, sen aika näyttää. DoubleM-pentuetta odotamme syntyväksi
            elokuun lopussa ja pentue on pitkälti ennakkoon varattu jo ennen
            astutusta.
          </p>
        </>
      ),
      img: require("../../assets/news/4-1.jpg"),
    },
    {
      header: "SUKOKAN DRIVE-IN JOUKKOTARKASTUKSESSA LEMPÄÄLÄSSÄ",
      date: "11.6.2020",
      content: (
        <>
          <p>
            Kasvateideni Riesan, Ihmeen ja Kuningas Uunon silmät tarkastettu
            PEVISA:n mukaisesti ja kaikkien silmät todettu terveiksi.
          </p>
        </>
      ),
      img: require("../../assets/news/2-2.jpg"),
    },
    {
      header: "RIESAN POIKAKAVERIA TAPAAMASSA!",
      date: "6.6.2020",
      content: (
        <>
          <p>
            Vuosi sitten Latvian voittajanäyttelyn valioluokassa esiintyi omaan
            näkemykseen valtavan hieno uros. Tämä useampia valionarvoja
            saavuttanut uros oli kasvanut hyvin edellisvuoden Puolan EDS:n
            nuortenluokasta ja vakuutti Latviassa enenevissä määrin
            olemuksellaan, ilmeekkyydellään, luonteellaan ja käytöksellään. Upea
            uros, jolle tehty käyttöpuolen tulosta. Tänään kävin tapaamassa tätä
            urosta ja lyömässä omistajan kanssa kättä päälle jalostuskuvioille.
            Riesan juoksuja odotetaan alkavaksi viimeistään elokuussa.
          </p>
        </>
      ),
      img: require("../../assets/news/3-1.jpg"),
    },
    {
      header: "PETTYMYKSIÄ JA PENTUESUUNNITELMIA",
      date: "22.5.2020",
      content: (
        <>
          <p>
            Ikäväksemme on kerrottava, että Harmille ei ole odotuksista
            huolimatta tulossa kesäpentuja. Röntgensäteet ovat tänään
            vahvistaneet omat havaintomme ja viikko sitten tehdyn
            ultraäänitutkimuksen tyhjät löydökset. Pettymyksen tunteiden seasta
            nousee kuitenkin ajatus, että luonnolla on tapana hoitaa asiat
            hyvästä syystä.
          </p>
          <p>
            Palaamme pentuesuunnitelmien julkaisemisen suhteen askeleen
            käytännöissämme taaksepäin ja julkaisemme suunnitelmia hyvissä ajoin
            etukäteen odottavalle yleisölle. Luvassa on uusintayritystä suuren
            mielenkiinnon herättäneeseen yhdistelmäsuunnitelmaan Amina Award For
            Me x Giantpaw's Joker, jo julkaistu varsinainen "bad ass"-yhdistelmä
            Giantpaw's Gryscha x E.L. Vincitore for Irmatas sekä porilaisittain
            ilmaistuna "vähä helmi" -yhdistelmä Darling Z Gildii Nieustraszonych
            x Giantpaw's Don Juan. Lisäksi on muitakin ajatuksia pentueiden
            suhteen, mutta jätetään niistä kertominen tuonnemmaksi. Lisätietoa
            löydät pentuja-sivulta. Yhdistelmävedokset siellä saavat lihaa
            luiden ympärille kun astutusajankohdat lähenevät.
          </p>
        </>
      ),
      img: require("../../assets/news/7.jpg"),
    },
    {
      header: "PENTUJEN ODOTUSTA!",
      date: "1.5.2020",
      content: (
        <p>
          Harmin astutus maaliskuun lopussa tuottaa vatsasta päätellen tulosta,
          odotamme pentuetta (Giantpaw's First Choice x BH Multi Ch. Volvo
          Amicidecorso) syntyväksi touko-kesäkuun vaihteeseen. Pentue alkaa
          hiljalleen olemaan alustavasti loppuun varattu
        </p>
      ),
      img: require("../../assets/news/6.jpg"),
    },
    {
      header: "COVID-19",
      date: "4.4.2020",
      content: (
        <p>
          Olemme viettäneet koronaviruksen vuoksi hiljaiseloa harrastuspuolella
          ja pysytelleet pääasiassa eristäytyneenä kotona ja lähimaastossa.
          Mikäli haluat tulla tutustumaan laumaamme koronaviruksen jyllätessä,
          niin on se mahdollista, mutta pyydämme ottamaan huomioon, että
          kenneltyttömme kuuluu riskiryhmään.
        </p>
      ),
      img: require("../../assets/news/4.jpg"),
    },
    {
      header: "ASTUTUSPUUHIA TIEDOSSA",
      date: "15.3.2020",
      content: (
        <p>
          Useamman vuoden pohdinnan tuloksena olemme päätyneet astuttamaan
          Harmin (Giantpaw’s First Choice) kevätjuoksuista maaliskuun lopussa.
          Yhdistelmään on tulossa melko mielenkiintoinen linjaus, joten varmasti
          on perehtymisen arvoinen pentue tuloillaan. Lisätietoja yhdistelmästä
          löydät PENNUT-sivulta. Huomaathan perehtyä myös pentuinfoomme.
        </p>
      ),
      img: require("../../assets/news/15.3.jpg"),
    },
    {
      header: "TERVEISET VIRON ERIKOISNÄYTTELYSTÄ",
      date: "1.3.2020",
      content: (
        <>
          <p>
            Osallistuimme Riesan ja Ruutin kanssa Viron "Mastiff Winner 2020"
            -näyttelyyn Tallinnassa. Tuomarina Ilona Onstenk-Schenk (Hollanti).
          </p>
          <p>
            Giantpaw's Lady La Miracle debytoi pentukehässä ollen erittäin
            lupaava, ROP-pentu ja BIS2-pentu. Arvostelu: "6 months, good size,
            nice head, good propotions, square skull, good lenght of muzzle,
            good bone and feet. Medium angulations. Good musculation behind.
            Very promising."
          </p>
          <p>
            Giantpaw's Gryscha avoimessa luokassa saaden ERI, SA, PN2 ja SERT.
            Arvostelu: "3 years, very feminine. Very nice head. Good stop and
            length of muzzle, nice eyes. Excellent teeth. Medium bone. Good
            topline. Good hindguarters. Moves with medium drive".
          </p>
        </>
      ),
      img: require("../../assets/news/1.3.jpg"),
    },
    {
      header: "HYVÄÄ YSTÄVÄNPÄIVÄÄ!",
      date: "14.2.2020",
      content: (
        <p>
          Olemme saaneet sovituksi ulkomaalaisen uroksen omistajan kanssa
          tulevaisuuden pentueen toteuttamisesta. Yhdistelmänä Giantpaw’s
          Gryscha (Riesa) x Multi Ch. BH IGBH1 E.L. Vincitore for Irmatas.
          Tavoitteena on juoksuttaa Riesa valioksi ennen pentuemainoksen
          julkaisemista. Pentue ajankohtainen vuoden vaihteessa. Pentue pitkälti
          varattu.
        </p>
      ),
      img: require("../../assets/news/14.2.jpg"),
    },
    {
      header: "LUONTOÄITI OLI ERI MIELTÄ",
      date: "5.2.2020",
      content: (
        <p>
          Priimaluustoinen Usva tiineysultrattu tyhjäksi. Suunniteltu pentue
          Amina Award For Me x LTJMVA LTJV-19 Giantpaw’s Joker siirtyy
          tulevaisuuteen. Pentuesuunnitelmat kuluvalle vuodelle julkaistaan
          myöhemmin. Jää seurailemaan.
        </p>
      ),
      img: require("../../assets/news/5.2.jpg"),
    },
    {
      header: "ERINOMAINEN STARTTI VUOTEEN 2020",
      date: "26.1.2020",
      content: (
        <>
          <p>
            Meillä oli oikein onnistunut päivä tänään Turun kansainvälisessä
            koiranäyttelyssä. Tuomarina Dragan Stojanovic (Bosnia-Hertsegovina).
          </p>
          <p>
            Giantpaw's Goljatt ERI, SA, PU1, CACIB, ROP: "Medium size. Medium
            strong. Correct head. Good expression. Correct standing. Correct
            movement. Coat and colour very good."
          </p>
          <p>
            Giantpaw's Gryscha ERI, SA, PN1, CACIB, CAC, VSP: "Right size.
            Correct propotions. Correct head. Brown eyes. Rear and front
            angulations ok. Movement ok."
          </p>
        </>
      ),
      img: require("../../assets/news/26.1.jpg"),
    },
    {
      header: "TERVEISIÄ KYÖSTI-ARMAALTA!",
      date: "22.1.2020",
      content: (
        <p>
          Mä maalaispoika oon... Giantpaw's Joker (Giantpaw's Big Mama x
          Giantpaw's Goljatt) 20kk. Kyösti-Armas on toipunut hyvin viime vuoden
          lavan tähystysleikkauksen vaatineesta tapaturmasta. Kävimme
          tuomarikoulutuksessa mallikoirina ja pojan liike arvosteltiin oikein
          kauniiksi ja puhtaaksi. Toiveikkaana odotamme kuinka komea poika
          Kyösti-Armaasta massaantumisen myötä kehittyykään. Viralliset
          luustokuvaustuloksetkin näkyvät jo jalostustietojärjestelmässä, HD C/D
          ja ED 0/0.
        </p>
      ),
      img: require("../../assets/news/22.1.jpg"),
    },
    {
      header: "VUODEN VOITOKKAIN 2019 TULOKSET ON LASKETTU!",
      date: "13.1.2020",
      content: (
        <>
          <p>
            Viralliset tulokset rotujärjestö Bullmastiffit&Mastiffit ry:n Vuoden
            Voitokkain –kilpailussa on julkaistu tänään. Lauman päävahti Multi
            ch. & Multi winner Giantpaw's Big Mama aka Irma on Vuoden Voitokkain
            cane corso narttu 2019 rotujärjestön alaisten rotujen kaikkein
            korkeimmalla pistemäärällä sekä myös Vuoden Voitokkain Veteraani
            korkeimmilla pisteillä kaikkien rotujärjestön alaisten rotujen
            veteraaneista. Aika WOW !!!
          </p>
          <p>
            ☆Giantpaw's Hazard High Life☆ Vuoden 2018 voitokkain sijoittui
            neljällä näyttelytuloksella kolmanneksi voitokkaimmaksi cane corso
            nartuksi vuonna 2019.
          </p>
          <p>
            ☆Giantpaw's Goljatt☆ sijoittui neljällä näyttelytuloksella vuoden
            2019 toiseksi voitokkaimmaksi cane corso urokseksi..
          </p>
          <p>
            Vuoden voitokkain tulokset{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.mastiffit.com/vuoden-voitokkain-2019"
            >
              täältä.
            </a>
          </p>
          <p>
            Nämä palkinnot nivovat yhteen huikean näyttely vuotemme 2019, josta
            päälimmäisenä mielessä äidin ja tyttären saavutukset. Giantpaw’s
            Hazard High Life aka Ihme sai nimensä eteen Suomen, Ruotsin, Norjan,
            Pohjoismaiden ja Viron MVA:n arvot. Veteraanikehät tammikuussa
            aloittanut Irma saavutti virallisena Liettuan, Latvian, Viron,
            Baltian ja Unkarin veteraani MVA:n arvot sekä Tallinnan, Liettuan,
            Viron, Latvian, Itävallan, Helsingin ja Suomen veteraani voittaja
            2019 –tittelit. Irmalle lisäksi saavutuksina
            jalostustietojärjestelmästä näkymättömät Tallinnan Winter Cup,
            Kaunas, Kaunas Kikos Taure, Vilniaus Taure, Vilniaus Pavasaris
            veteraani voittaja 2019 –tittelit sekä kirsikkana kakun päällä
            Euroopan varaveteraanivoittaja 2019.
          </p>
        </>
      ),
      img: require("../../assets/news/13.1.jpg"),
    },
  ];
  return (
    <div id="whats-new">
      <div className="header">
        <img alt="Paw" src={require("../../assets/paw_left.svg")} />
        <h1>Ajankohtaista</h1>
      </div>
      <div className="posts">
        {posts.map((post, i) => (
          <Post key={i} post={post} />
        ))}
      </div>
    </div>
  );
};

export default WhatsNew;
