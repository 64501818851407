import React from "react";
import "./DogList.scss";

const Dog = (props) => {
  const { dog } = props;
  return (
    <div className="dog">
      <div className="text">
        <h2>{dog.name}</h2>
        {dog.sub && <p style={{marginBottom: 10}}>{dog.sub}</p>}
        {dog.sukutaulu != null ? (
          dog.sukutaulu && (
            <button onClick={() => window.open(dog.sukutaulu, "_blank")}>
              Sukutaulu
            </button>
          )
        ) : (
          <>
            <button onClick={() => window.open(dog.ema_sukutaulu, "_blank")}>
              Emän sukutaulu
            </button>
            <button onClick={() => window.open(dog.isa_sukutaulu, "_blank")}>
              Isän sukutaulu
            </button>
          </>
        )}
        {dog.jalostustietojarjestelma && (
          <button
            onClick={() => window.open(dog.jalostustietojarjestelma, "_blank")}
          >
            Jalostustietojärjestelmä
          </button>
        )}
        <button onClick={() => window.open(dog.kuvia, "_blank")}>Kuvia</button>
      </div>
      <img alt="Dog img" src={dog.img} />
      <h2 className="mob-title">{dog.name}</h2>
    </div>
  );
};

const DogList = (props) => {
  const { dogs } = props;
  return (
    <div className="dogs">
      {dogs.map((dog) => (
        <Dog dog={dog} />
      ))}
    </div>
  );
};

export default DogList;
