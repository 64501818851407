import React from "react";
import "./AboutUs.scss";

const AboutUs = (props) => {
  return (
    <div id="about-us">
      <div className="content">
        <div className="header">
          <img alt="Paw" src={require("./assets/paw_left.svg")} />
          <h1>Meistä</h1>
        </div>
        <div className="first">
          <img
            className="desktop-img"
            alt="1"
            src={require("./assets/dogs/1.jpg")}
          />
          <div className="texts">
            <p>
              <strong>
                Hei, Giantpaw's-kennelnimen takana puuhastelee pääpromoottorina
                ja kennelnimen haltijana Satu Pörsti.
              </strong>
            </p>
            <img
              className="mobile-img"
              alt="1"
              src={require("./assets/dogs/1.jpg")}
            />
            <p>
              Kennelnimi on FCI:n ja Suomen Kenneliiton hyväksymä ja olen
              Kennelliiton aktiivikasvattaja. Giantpaw's:n Päämaja sijaitsee
              Länsi-rannikon karhukaupungissa Porissa. Corsotilukset sijaitsevat
              maaseudulla erinomaisten harrastusmaastojen ympäröimänä aivan
              Porin Royal Canin Sport Centerin läheisyydessä, maaseudun
              rauhassa. Olen Suomen Kennelliiton jäsen, rotujärjestö
              Bullmastiffit & Mastiffit ry:n sekä Suomen Koirankasvattajat
              (SuKoKa) ry:n jäsen. Olen myös Cane Corso Club Finland ry:n
              perustajajäsen reilun 12 vuoden hallitustyöskentelypanostuksella,
              CCCF ry:n toiminnasta poistuin vuonna 2017. Olen toiminut myös
              rotujärjestön hallituksessa ja useamman kauden cane corsojen
              jalostustoimikunnan puheenjohtajana. Olen suorittanut Suomen
              Kennelliiton kasvattajan perus- ja jatkokurssin sekä
              Opetushallituksen valvonnassa hajukoirakoulutuksen ja
              profiloitunut koirieni kanssa siihen. Cane corsot, niiden
              kasvattaminen ja niiden kanssa harrastaminen ovat vapaa-ajan
              täyttävä elämäntapa. Corsojen kanssa harrastaminen painottuu tänä
              päivänä tottelevaisuuskoulutukseen, näyttelyihin, peltojälkeen ja
              hajutyöskentelyyn. Tavoitteellista, kilpailuihin tähtäävää
              harrastamisesta olen itse joutunut valitettavasti luopumaan, sillä
              kasvatan corsoja OTO, oman toimen ohella ja suurehkon lauman
              tyytyväisenä ja onnellisena pitäminen vie suurimman osan ajastani.
            </p>
          </div>
        </div>
        <div className="text-block right">
          <div>
            <p>
              Cane corsoihin tutustuin 2000-luvun alkupuolella, ensimmäisen
              corsoni vuonna 2004 syntyneen Fin MVA Doro Dei Paesi Bassin myötä
              sydämeni rodulle oli lopullisesti mennyttä. Ensimmäinen
              corsopentueeni näki päivän valon vuonna 2007 ja siitä lähtien,
              säännöllisen epäsäännöllisesti on corsopentue syntynyt. Laumaani
              kotona asuttaa tälllä hetkellä seitsemän corsoa, viisi narttua ja
              kaksi urosta. Koirani asuvat ja osallistuvat arkeen asuintiloissa,
              erillisiä kenneltiloja en suosi. Näiden seitsemän kotona asustavan
              lisäksi omistan lukuisia kasvateitani ja ulkomaan tuonteja, jotka
              asuvat yhteistyökodeissaan.
            </p>
            <p style={{ marginTop: 20 }}>
              Lokakuussa 2018 pyysin Suomen Kennelliiton virallisen konsultoijan
              tarkastamaan kasvatustoimintaani ja kasvatusolosuhteita hieman
              perusteellisemmin. Kennelneuvojan mukaan koirien tilat ovat
              keskitason yläpuolella sekä kennelillä on selkeästi hyvät
              olosuhteet kasvatustyöhön. Kennelneuvojan raportin mukaan
              kasvattajalla on hyvä ote kasvatustyöhön ja realistiset käsitykset
              koiristaan ja kasvateistaan.
            </p>
          </div>
          <img alt="1" src={require("./assets/dogs/2.jpg")} />
        </div>
        <div className="text-block left">
          <img alt="3" src={require("./assets/dogs/3.jpg")} />
          <div>
            <p>
              Olen tehnyt ilmoituksen AVIin ammattimaisesta tai muuten laajasta
              eläintenpidosta, vaikka toimintani ei niin laajaa olekaan.
              Marraskuussa 2018 kennel Giantpaw's rekisteröitiin LSAVIin
              eläinsuojelulain 247/1996 24§:n mukaiseksi eläinten pitopaikaksi
              tahtoni mukaisesti. Satakunnan ympäristöterveydenhuollon
              valvontasuunnitelman mukaisesti ilmoituksen tehneet kohteet
              pyritään tarkastamaan säännöllisesti. Kennel Giantpaw's on
              19.12.2019 viimeksi tarkastettu valvontaeläinlääkärin toimesta ja
              valvontaeläinlääkärin palaute oli, että erittäin hyvin, laajasti
              ja rodunomaiset tarpeet huomioonottaen on kohde suunniteltu
              lähtökohtana kasvatustoiminta ja eläintenpito. Tilat palvelevat
              erinomaisesti, koirat ovat iloisia ja tyytyväisiä.
            </p>
            <p style={{ marginTop: 20 }}>
              Joulukuussa 2018 ilmestyneessä maailmanlaajuisessa The Cane Corso
              Magazinessa (issue #8) on kasvattajahaastattelu kennelin takaa,
              voit lukea sen ilmaiseksi verkkoversiona osoitteessa{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.canecorso-magazine.com/magazines/no8/no8.html"
              >
                tästä.
              </a>{" "}
              Kennelistä on tehty myös muita lehtiartikkeleita, esimerkiksi
              Koirat -lehdessä (2/2014) rotuesittelyssä on Cane Corso ja
              kennelin kasvattaja haastateltavana.
            </p>
          </div>
        </div>
        <p style={{ marginTop: 40 }}>
          Tavoitteenani on kasvattaa kaikin puolin terveitä ja rodunomaisia sekä
          käyttötarkoitukseensa soveltuvia cane corsoja. Pienimuotoisen
          kenneltoimintani pyrin pitämään avoimena ja rehellisenä, jonka katson
          antavan luottamuksen arvoisen imagon. Kasvatan pentuja harkitusti,
          niin että jokainen yhdistelmä on tarkoin suunniteltu. Jokainen
          yhdistelmä on suunniteltu siten, että valitsen niistä myös itselleni
          jalostusohjelmaan ja käyttötarkoituksiini soveltuvan pennun.
          Jalostukseen pyrin käyttämään ainoastaan luonne–ominaisuuksiltaan
          tyypillisiä, työkykyisiä ja aktiivisia koiria. Kasvatustyössäni pyrin
          mahdollisimman rotutyypillisiin, tasapainoisiin ja terverakenteisiin
          koiriin, unohtamatta ulkomuotoa sekä leimaa antavia ulkomuodon pään ja
          ilmeen piirteitä.
        </p>
        <p>
          Tervetuloa tutustumaan kotisivuihini! Ethän epäröi olla yhteydessä
          mikäli haluat tulla tutustumaan koiriini! Otan rodusta kiinnostuneet
          mielelläni vierailulle.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
