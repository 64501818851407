import React from 'react';
import './Banner.scss';

const Banner = props => {
    return (
        <div id="banner">
            <img alt="Giantpaw's logo" src={require("./assets/logo2.svg")} />
        </div>
    );
}

export default Banner;