import React from "react";
import "./Main.scss";
import Banner from "./Banner";
import NavBar from "./NavBar";
import AboutUs from "./AboutUs";
import Footer from "./Footer";
import { Switch, Route, withRouter } from "react-router-dom";
import WhatsNew from "./pages/WhatsNew/WhatsNew";
import Dogs from "./pages/Dogs/Dogs";
// import InMemoriam from "./pages/InMemoriam/InMemoriam";
import Puppies from "./Puppies/Puppies";
import PuppyInfo from "./pages/PuppyInfo/PuppyInfo";
import ContactUs from "./pages/ContactUs/ContactUs";
import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

class App extends React.PureComponent {
  constructor(props) {
    super(props);

    this.navItems = [
      {
        title: "Meistä",
        component: <AboutUs />,
        path: "/",
      },
      {
        title: "Ajankohtaista",
        component: <WhatsNew />,
        path: "/news",
      },
      {
        title: "Koirat",
        component: <Dogs />,
        path: "/dogs",
      },
      {
        title: "Pentuja",
        component: <Puppies />,
        path: "/puppies",
      },
      {
        title: "Pentuinfo",
        component: <PuppyInfo />,
        path: "/puppyinfo",
      },
      {
        title: "Yhteystiedot",
        component: <ContactUs />,
        path: "/contact",
      },
    ];
    const navIndex = this.navItems.findIndex((n) =>
      this.props.location.pathname.endsWith(n.path)
    );

    this.state = {
      selectedPage: navIndex,
      scrollOffset: 0,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this._onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._onScroll);
  }

  _onScroll = () => {
    this.setState({ scrollOffset: window.pageYOffset });
  };

  _renderContent = () => {
    const { selectedPage } = this.state;
    return (
      <Switch>
        <Route path={this.navItems[selectedPage].path}>
          {this.navItems[selectedPage].component}
        </Route>
      </Switch>
    );
  };

  setCurrentPage = (item, i) => {
    this.setState({ selectedPage: i });
    this.props.history.push(item.path);
    window.scrollTo({
      top: 0,
    });
  };

  render() {
    const { selectedPage, scrollOffset } = this.state;
    return (
      <div onScroll={this._onScroll} className="App">
        <NavBar
          offset={scrollOffset}
          selectedPage={selectedPage}
          items={this.navItems}
          onItemClick={this.setCurrentPage}
        />
        <Banner />
        {this._renderContent()}
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
