import React from "react";
import "./Dogs.scss";
import DogList from "../../DogList/DogList";
import { useState } from "react";

const Dogs = (props) => {
  const males = [
    {
      name: "MULTI WINNER LA PROTEZIONE MIGLIORE HANNIBAL",
      img: require("../../assets/dogs/kuva15.jpg"),
      sukutaulu:
        "http://www.canecorsopedigree.com/modules/animal/pedigree.php?pedid=26243",
      jalostustietojarjestelma:
        "https://jalostus.kennelliitto.fi/frmKoira.aspx?RekNo=FI40964/14&R=343",
      kuvia:
        "https://www.facebook.com/cane.corso.5492216/media_set?set=a.199853634444236&type=3",
    },
    {
      name: "FI & EE MVA GIANTPAW’S GOLJATT",
      img: require("../../assets/dogs/kuva16.jpg"),
      sukutaulu:
        "http://www.canecorsopedigree.com/modules/animal/pedigree.php?pedid=77467",
      jalostustietojarjestelma:
        "https://jalostus.kennelliitto.fi/frmKoira.aspx?RekNo=FI21607/16&R=343",
      kuvia:
        "https://www.facebook.com/cane.corso.5492216/media_set?set=a.126117668484500&type=3",
    },
    {
      name: "LT JMVA LTJV-19 GIANTPAW’S JOKER",
      img: require("../../assets/dogs/kuva17.jpg"),
      sukutaulu:
        "http://www.canecorsopedigree.com/modules/animal/pedigree.php?pedid=85181",
      jalostustietojarjestelma:
        "https://jalostus.kennelliitto.fi/frmKoira.aspx?RekNo=FI31855/18&R=343",
      kuvia:
        "https://www.facebook.com/cane.corso.5492216/media_set?set=a.126016145161319&type=3",
    },
    {
      name: "MULTI CHAMPION GIANTPAW'S MURIAN",
      img: require("../../assets/dogs/kuva18.jpg"),
      sukutaulu:
        "https://canecorsopedigree.com/view_pedigree?id=100959",
      jalostustietojarjestelma:
        "https://jalostus.kennelliitto.fi/frmKoira.aspx?RekNo=FI50430/20&R=343",
      kuvia:
        "https://www.facebook.com/media/set/?set=a.946257966470462&type=3",
    },
    {
      name: "GIANTPAW'S OMOTOSE",
      img: require("../../assets/dogs/kuva19.jpg"),
      sukutaulu:
        "https://canecorsopedigree.com/view_pedigree?id=100977",
      jalostustietojarjestelma:
        "https://jalostus.kennelliitto.fi/frmKoira.aspx?RekNo=FI23913/21&R=343",
      kuvia:
        "https://www.facebook.com/media/set/?set=a.575164596913136&type=3",
    },
    {
      name: "C.I.B.-J FI&EE JCH EEJW-23 EEW-23 GIANTPAW'S RASTABAN",
      img: require("../../assets/dogs/kuva20.jpg"),
      sukutaulu:
        "",
      jalostustietojarjestelma:
        "https://jalostus.kennelliitto.fi/frmKoira.aspx?RekNo=FI36098/22&R=343",
      kuvia:
        "https://www.facebook.com/media/set/?set=a.663273988102196&type=3",
    },
    {
      name: "LTU PCH GIANTPAW'S TROJAN",
      img: require("../../assets/dogs/kuva21.jpg"),
      sukutaulu:
        "",
      jalostustietojarjestelma:
        "https://jalostus.kennelliitto.fi/frmKoira.aspx?RekNo=FI18833/23&R=343",
      kuvia:
        "https://www.facebook.com/media/set/?set=a.948831146213144&type=3",
    },
  ];
  const females = [
    {
      name: "MULTI CHAMPION GIANTPAW’S BIG MAMA",
      img: require("../../assets/dogs/giantpaws_big_mama.jpg"),
      sukutaulu:
        "http://www.canecorsopedigree.com/modules/animal/pedigree.php?pedid=22160",
      jalostustietojarjestelma:
        "https://jalostus.kennelliitto.fi/frmKoira.aspx?RekNo=FI15065/11&R=343",
      kuvia:
        "https://www.facebook.com/cane.corso.5492216/media_set?set=a.199819111114355&type=3",
    },
    {
      name: "MULTI CHAMPION GIANTPAW’S HAZARD HIGH LIFE",
      img: require("../../assets/dogs/10.jpg"),
      sukutaulu:
        "http://www.canecorsopedigree.com/modules/animal/dog.php?id=77515",
      jalostustietojarjestelma:
        "https://jalostus.kennelliitto.fi/frmKoira.aspx?RekNo=FI14887%2F17&R=343",
      kuvia:
        "https://www.facebook.com/cane.corso.5492216/media_set?set=a.199827131113553&type=3",
    },
    {
      name: "FI&EE CH GIANTPAW'S GRYSCHA",
      img: require("../../assets/dogs/giantpaws_gryscha.jpg"),
      sukutaulu:
        "http://www.canecorsopedigree.com/modules/animal/pedigree.php?pedid=77470",
      jalostustietojarjestelma:
        "https://jalostus.kennelliitto.fi/frmKoira.aspx?RekNo=FI21610/16&R=343",
      kuvia:
        "https://www.facebook.com/cane.corso.5492216/media_set?set=a.199810051115261&type=3",
    },
    {
      name: "AMINA AWARD FOR ME",
      img: require("../../assets/dogs/kuva10.jpg"),
      sub: "Imp. Ukraina, Ped. UKU.0363733",
      ema_sukutaulu:
        "http://www.canecorsopedigree.com/modules/animal/pedigree.php?pedid=87614",
      isa_sukutaulu:
        "http://www.canecorsopedigree.com/modules/animal/pedigree.php?pedid=87613",
      jalostustietojarjestelma:
        "https://jalostus.kennelliitto.fi/frmKoira.aspx?RekNo=FI21138/20&R=343",
      kuvia:
        "https://www.facebook.com/cane.corso.5492216/media_set?set=a.126118328484434&type=3",
    },
    {
      name: "FI&EE CH FIW-22 DARLING Z GILDII NIEUSTRASZONYCH",
      img: require("../../assets/dogs/kuva11.jpg"),
      sub: "Imp. Puola, Ped. PKR.II-134474",
      sukutaulu: "https://canecorsopedigree.com/view_pedigree?id=100982",
      jalostustietojarjestelma:
        "https://jalostus.kennelliitto.fi/frmKoira.aspx?RekNo=PKR.II-134474&R=343",
      kuvia:
        "https://www.facebook.com/cane.corso.5492216/media_set?set=a.126007128495554&type=3",
    },
    {
      name: "GIANTPAW’S LADY PRINCESS UNA",
      img: require("../../assets/dogs/kuva12.jpg"),
      sukutaulu: "https://canecorsopedigree.com/view_pedigree?id=100966",
      jalostustietojarjestelma:
        "https://jalostus.kennelliitto.fi/frmKoira.aspx?RekNo=FI45832%2F19&R=343",
      kuvia:
        "https://www.facebook.com/cane.corso.5492216/media_set?set=a.200575894372010&type=3",
    },
    {
      name: "FI&EE CH GIANTPAW'S OURANIA",
      img: require("../../assets/dogs/kuva13.jpg"),
      sukutaulu: "https://canecorsopedigree.com/view_pedigree?id=100972",
      jalostustietojarjestelma:
        "https://jalostus.kennelliitto.fi/frmKoira.aspx?RekNo=FI23922/21&R=343",
      kuvia: "https://www.facebook.com/media/set/?set=a.453066669122930&type=3",
    },
    {
      name: "C.I.B.-J FI&EE JCH EEJW-23 GIANTPAW'S SEÑORITA POR FAVOR",
      img: require("../../assets/dogs/kuva14.jpg"),
      sukutaulu: "",
      jalostustietojarjestelma:
        "https://jalostus.kennelliitto.fi/frmKoira.aspx?RekNo=FI41613%2F22&R=343",
      kuvia: "https://www.facebook.com/media/set/?set=a.775876746841919&type=3",
    },
  ];

  const [mode, setMode] = useState("female");
  return (
    <div id="dogs">
      <div className="header">
        <img alt="Paw" src={require("../../assets/paw_left.svg")} />
        <h1>Koirat</h1>
      </div>
      <div className="tabs">
        <div
          onClick={() => setMode("female")}
          className={mode === "female" ? "active" : ""}
        >
          <h2>{"Nartut"}</h2>
          <img alt="Icon" src={require("../../assets/narttu.svg")} />
        </div>
        <div
          onClick={() => setMode("male")}
          className={mode === "male" ? "active" : ""}
        >
          <h2>{"Urokset"}</h2>
          <img alt="Icon" src={require("../../assets/uros.svg")} />
        </div>
      </div>
      <DogList dogs={mode === "female" ? females : males} />
    </div>
  );
};

export default Dogs;
