import React from "react";
import "./Footer.scss";
import { FaFacebook, FaEnvelope, FaInstagram } from "react-icons/fa";

const Footer = (props) => {
  return (
    <div id="footer">
      © Kennel Giantpaw's, 2020
      <div className="some">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/kennel_giantpaws/"
        >
          <FaInstagram />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/kennelgiantpawscanecorso/"
        >
          <FaFacebook />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="mailto:info.giantpaw@gmail.com"
        >
          <FaEnvelope />
        </a>
      </div>
    </div>
  );
};

export default Footer;
