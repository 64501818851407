import React from "react";
import "./Puppies.scss";

const Puppies = (props) => {
  const plans = [
    {
      description: (
        <>
          <p>
            Suunnitteilla U- ja V-pentueet alkuvuoteen 2024. Tulevista
            pentueista informoidaan ajallaan, mutta mikäli kasvattimme
            kiinnostaa ja kasvatustyömme miellyttää niin olethan ajoissa ja
            kotiläksyt valmiiksi tehneenä yhteyksissä.
          </p>
        </>
      ),
      // image: require("../assets/image0.jpeg"),
      // ema_jalostus:
      //   "https://jalostus.kennelliitto.fi/frmKoira.aspx?RekNo=FI45836/19&R=343",
      // ema_kuvia_text: "Emän sukutaulu",
      // ema_kuvia: "https://canecorsopedigree.com/view_dog?id=100982",
      // isa_jalostus:
      //   "https://jalostus.kennelliitto.fi/frmKoira.aspx?RekNo=FI50433%2F20&R=343",
      // isa_kuvia_text: "Isän sukutaulu",
      // isa_kuvia: "https://canecorsopedigree.com/view_dog?id=85044",
    },
  ];
  return (
    <div id="puppies">
      <div className="content">
        <div className="header">
          <img alt="Paw" src={require("../assets/paw_left.svg")} />
          <h1>Pentuja</h1>
        </div>
        <p>
          <strong>
            OLETKO VAILLA HARRASTUSKAVERIA, USKOLLISTA YSTÄVÄÄ, VAHTIA JA
            SUOJELIJAA TAI NÄYTTELYKAVERIA?
          </strong>
        </p>
        <p style={{ marginTop: 10 }}>
          <strong>PENTUESUUNNITELMIA VUODELLE 2024!</strong>
        </p>
        {plans.map((plan) => {
          return (
            <div className="first">
              {/* <img alt="1" src={plan.image} /> */}
              <div className="texts">
                <p>{plan.description}</p>
                {/* <div className="btns">
                  <div className="btn-row">
                    <button
                      onClick={() => window.open(plan.ema_jalostus, "_blank")}
                    >
                      {plan.ema_jalostus
                        ? plan.ema_jalostus_text ||
                          "Emä jalostustietojärjestelmässä"
                        : "Emä ei Suomen rekisterissä"}
                    </button>
                    {plan.ema_kuvia && (
                      <button
                        onClick={() => window.open(plan.ema_kuvia, "_blank")}
                      >
                        {plan.ema_kuvia_text || "Kuvia emästä"}
                      </button>
                    )}
                  </div>
                  <div className="btn-row">
                    <button
                      style={{ opacity: plan.isa_jalostus ? 1 : 0.5 }}
                      onClick={() =>
                        plan.isa_jalostus &&
                        window.open(plan.isa_jalostus, "_blank")
                      }
                    >
                      {plan.isa_jalostus
                        ? plan.isa_jalostus_text ||
                          "Isä jalostustietojärjestelmässä"
                        : "Isä ei Suomen rekisterissä"}
                    </button>
                    <button
                      style={{
                        pointerEvents: plan.isa_kuvia ? "auto" : "none",
                        opacity: plan.isa_kuvia ? 1 : 0,
                      }}
                      onClick={() =>
                        plan.isa_kuvia && window.open(plan.isa_kuvia, "_blank")
                      }
                    >
                      {plan.isa_kuvia_text ||
                        (plan.isa_kuvia ? "Kuvia isästä" : "Kuvat tulossa")}
                    </button>
                  </div>
                  {plan.isa2_kuvia && (
                    <div className="btn-row">
                      <button
                        style={{ opacity: plan.isa2_jalostus ? 1 : 0.5 }}
                        onClick={() =>
                          plan.isa2_jalostus &&
                          window.open(plan.isa2_jalostus, "_blank")
                        }
                      >
                        {plan.isa2_jalostus
                          ? "Isä 2 jalostustietojärjestelmässä"
                          : "Isä 2 ei jalostustietojärjestelmässä"}
                      </button>
                      <button
                        style={{ opacity: plan.isa2_kuvia ? 1 : 0.5 }}
                        onClick={() =>
                          plan.isa2_kuvia &&
                          window.open(plan.isa2_kuvia, "_blank")
                        }
                      >
                        {plan.isa_kuvia_text ||
                          (plan.isa2_kuvia
                            ? "Kuvia 2. isästä"
                            : "Kuvat tulossa")}
                      </button>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
          );
        })}
        <p>
          Lisätietoa yhdistelmistä saat ensisijaisesti puhelimitse tai
          sähköpostilla. Odotettavissa on rotumääritelmän mukaisia,
          harrastuskäyttöön soveltuvia yksilöitä. Pentueet ovat jo osittain
          varattuja suunnitteluvaiheessa, joten kannattaa hivuttautua
          pentujonoon ajoissa. Näistä tulee päheitä!
        </p>
        {/* <br />
        <h1>Tulevaisuuden pentueet</h1>
        <p>
          Seuraavissa pentueissa emäkoirat tulevat olemaan L-pentueestamme
          (Fi&Ee Mva Giantpaw's Goljatt x Multi Mva Giantpaw's Hazard High
          Life), yhdistelmätiedot julkaisemme myöhemmin. Pysy kuulolla!
        </p> */}
      </div>
    </div>
  );
};

export default Puppies;
