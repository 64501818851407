import React from "react";
import "./PuppyInfo.scss";

const PuppyInfo = (props) => {
  return (
    <div id="puppy-info">
      <div className="content">
        <div className="header">
          <img alt="Paw" src={require("../../assets/paw_left.svg")} />
          <h1>Pentuinfo</h1>
        </div>
        <p>
          Pyydämme pennusta ja rodusta kiinnostuneita tutustumaan tähän
          pentuinfoomme sekä huomioimaan, että emme ota varauksia tai
          varausmaksua pennusta vastaan tapaamatta potentiaalisia kotiehdokkaita
          ensin henkilökohtaisesti. Mikäli haluat kasvattajan näkövinkkelistä
          antaa itsestäsi hyvän kuvan ollessasi ensi kertaa yhteydessä niin
          arvostamme vanhanaikaista yhteydenottoa puhelimitse. Voit tulla
          tapaamaan meitä myös joihinkin kilpailuihin ja tapahtumiin.
          Ensisijaisesti toivomme, että olet kiinnostunut tulemaan vierailulle
          kenneliimme, mielestämme se on fair play. Tapaat koiramme, niiden
          elinolot ja mahdollisen tulevan koirasi kasvattajan. Mielestämme
          malliyksilöiden tapaaminen antaa sinulle suhteellisen hyvän ja
          realistisen kuvan rodusta, myös rodusta kertominen on helppoa
          live-esiintyjien parissa.
        </p>
        <p>
          Jokainen pentueemme suunnitellaan ja toteutetaan siinä toivossa, että
          pentueeseen syntyisi myös jatkumoa kasvatustyöllemme. Eri pentueissa
          on eri painotuksia, kerromme niistä halutessasi lisää. Kaikissa
          yhdistelmissämme on siis tarkoitus ja tavoite, sattumanvaraisia
          pentueita määrän lisäämiseksi ei tehdä. Useimmiten pentueesta jää myös
          kenneliin yksilö kasvamaan.
        </p>
        <p>
          Cane corso on älyläs, aktiivinen, vakaa ja verraton vartija sekä
          suojelija. Se on säyseä ja hellä omistajalleen, rakastaa perhettään
          yli kaiken, mutta tarpeen tullen siitä tulee urhea ihmisten, kodin
          sekä omaisuuden suojelija. Historiastaan huolimatta cane corso tulee
          kasvattaa johdonmukaisuudella ja hellällä kädellä. Ihmisten
          mielivaltaan alistaminen ei kuulu sen kasvatukseen, mutta cane corso
          tarvitsee mustavalkoisen kasvatuksen ja selkeät pelisäännöt. Aikuinen
          cane corso on fyysisesti voimakas ja suuri kokoinen, joten koiran
          peruskoulutus on välttämätöntä. Arkielämän mielekkyyden kannalta myös
          kotikoira on koulutettava ja totutettava käsittelyyn.
        </p>
        <p>
          Cane corso ei viihdy pelkkänä sohvatyynynä tai egon jatkeena, se
          tarvitsee aikaasi, virikkeitä ja toimintaa. Toivomme, että perehdyt
          rotuun ja rodun perinnöllisiin sairauksiin mahdollisuuksiesi mukaan jo
          ennen ensimmäistä yhteydenottoasi. Internetistä löydät esimerkiksi
          rotumääritelmän, jalostuksen tavoiteohjelman, rotujärjestön sekä
          jalostustietojärjestelmän, jotka antavat sinulle tietoa rodusta.
          Suosittelemme tutustumaan myös rotujärjestö Bullmastiffit&Mastiffit
          ry:n jäsenkasvattajien listaan ja rotujärjestön pentuvälitykseen.
          Pyydämme huomioimaan, että huolellinen tutustuminen rotuun,
          kasvattajiin ja rodunedustajiin antaa sinulle kattavan näkemyksen,
          jonka pohjalta oman koiran ja mieluisan kasvattajan valitseminen on
          hedelmällistä. Huomioithan myös rodun erityispiirteet ja sen
          soveltuvuuden elämääsi ja elinympäristöösi.
        </p>
        <p>
          Tavoitteenamme on kasvattaa terveitä ja rodunomaisia cane corsoja.
          Pyrimme löytämään kasvateillemme yhteistyökykyiset, vastuuntuntoiset,
          määrätietoiset ja pysyvät kodit. Pennut kotiutetaan ensisijaisesti
          harrastaviin ja näyttelyistä kiinnostuneisiin koteihin, mutta myös
          kotikoiraksi. Ole rohkeasti yhteydessä, mikäli cane corso sinua rotuna
          kiinnostaa.
        </p>
        <p>
          Cane corso on työkoira-rotu, joka tarvitsee aktiivisen kodin
          vastatakseen käyttötarkoitustaan. Cane corsoille on myönnetty Suomessa
          kilpailuoikeus palveluskoirakokeisiin 1.3.2014 alkaen ja FCI on
          luokitellut cane corson käyttökoiraksi muutosrotumääritelmän myötä
          17.12.2015. Ennen pennun varaamista kannattaa miettiä, että millaiseen
          käyttötarkoitukseen olet cane corsoa hankkimassa. Luonnollisesti myös
          muut kuin aktiiviharrastajat ja näyttelyintoilijat huomioidaan
          pentueiden kohdalla, sillä corso taipuu myös loistavasti kodin
          vahdiksi ja perheen suojelijaksi. Harrastajille kennelimme on
          ensimmäisestä pentueesta lähtien luvannut kaupanteon yhteydessä, että
          jokaisen pentueen ensimmäisen koirakon saavuttaessa PK-puolen
          käyttövalion arvon palkitaan saavutus uudella pennulla. Sittemmin
          olemme päätyneet kannustamaan pennunottajia harrastamaan ja
          kouluttamaan koiriaan hajutyöskentelyn saralla sekä luvanneet uuden
          pennun myös mm. virallista hypokoulutustodistusta vastaan.
        </p>
        <p>
          <a href="http://www.mastiffit.com/">
            Rotujärjestö Bullmastiffit&Mastiffit ry
          </a>
        </p>
        <p>
          <a href="https://jalostus.kennelliitto.fi/">
            Kennelliiton jalostustietojärjestelmä
          </a>
        </p>
        <h2>Pennun varaaminen</h2>
        <p>
          Ennen pentujen syntymää otamme vastaan vain alustavia varauksia.
          Pennuista kiinnostuneet voivat käydä vierailulla kennelissämme ja
          tutustumassa tuleviin vanhempiin ja muihin sukulaisiin. Vierailun tai
          muun henkilökohtaisen tapaamisen jälkeen sovimme mahdollisuudesta
          tehdä alustavan varauksen. Pennusta kiinnostuneet jättävät
          yhteystietonsa ja kertovat toiveen mm. mahdollisen pennun sukupuolesta
          ja aiotusta käyttötarkoituksesta. Pentujen syntymän jälkeen
          ilmoitamme, että onko toiveen mukaista (sukupuoli) pentua syntynyt.
          Mikäli toiveiden mukainen pentu on syntynyt, sovimme alustavan
          varauksen tehneen kanssa tapaamisesta ja varausmaksun suorittamisesta.
        </p>
        <h2>Pentujen syntymä</h2>
        <p>
          Pentujen syntymisen ajankohtaan pyrimme välttämään massiivisia
          rotuesittelyjä kotonamme. Pentujen synnyttyä pentula rauhoitetaan
          muutamaksi viikoksi vierailta. Otamme vastaan rodusta kiinnostuneita
          ja esittelemme koiria ja toimintaamme mielellämme, mutta pentulan
          haluamme pitää rauhallisena ja ulkomaailman pöpöistä puhtaana.
          Haluamme näin luoda emälle ja pennuille mahdollisimman tasapainoisen
          ja vakaan alun.
        </p>
        <p>
          Pentueet kasvavat kotiympäristössä, tottuen samalla arjen elämään.
          Pennut kulkevat kehitystasonsa mukaan myös mukana luonnossa ja
          vieraissa paikoissa, tottuen näin myös kodin ulkopuoliseen elämään.
          Pentujen avattua silmät ja nosutessa jaloilleen avataan pentulan ovet
          varovasti vierailijoille. Pentujen täytettyä kolme-neljä viikkoa olet
          tervetullut katsomaan pentuja ja emää. Tapaamisen yhteydessä
          keskustelemme tarkentavasti käyttötarkoitusestasi ja toiveistasi
          pentua kohtaan. Voit esittää toivomuksen parista yksilöstä, jotka
          miellyttävät sinua eniten. Viimeistään tässä vaiheessa otamme pennusta
          varausmaksun, joka on molempia osapuolia sitova. Varausta ei voi enää
          peruuttaa kuin siinä tapauksessa, että pentu sairastuu niin, ettei
          sitä voi luovuttaa tai eläinlääkärin tarkastuksessa löytyy jokin
          luovutuksen kyseenalaistava vika. Tällaisessa tapauksessa varausmaksu
          palautetaan ostajalle. Kasvattajana jätän omaan omistukseeni useamman
          yksilön pentueesta ja valitsen nämä vasta lähellä luovutusikää.
          Kennelin rauhan ja järjestyksen säilyttämiseksi eivät vieraat koirat
          voi vierailla sisätiloissa tai tarhauksissa ja muutenkin on suotavaa
          jättää oma lemmikki vierailun ulkopuolelle.
        </p>
        <p>
          Pentujen luovuttamisen ajankohdasta sovitaan jokaisen pennunottajan
          kanssa erikseen. Pääsääntöisesti pennut luovutetaan kahdeksan
          viikkoisina. Pennut luovutetaan Suomen Kennelliiton kirjallisin
          sopimuksin, asianmukaisesti madotettuina, eläinlääkärin tarkastamina
          terveysdokumentein, FCI-rekisteröityinä, DNA-testattuina, mikrosirutettuina,
          kirjallisin pentukohtaisin kansioin, jotka sisältävät kattavan
          hoito–oppaan, ruokintaohjeet, rotu–infon, linjojemme terveystietoja
          sekä muita mielestäni tarpeellisia dokumentteja.
        </p>
        <p>
          Kasvattajana edellytän, että jokaisen kasvatin omistajat sitoutuvat
          tekemään koiralleen viralliset PEVISA-tutkimukset. Vuonna 2019 olemme
          siirtyneet käytäntöön, jossa otamme kaupantekohetkellä panttimaksun
          PEVISA-tutkimusten osalta. Palautamme pantin, kun viralliset
          terveystutkimustulokset ovat nähtävissä jalostustietojärjestelmässä.
          Lisäksi edellytän, että jokainen vuoden 2018 jälkeen syntynyt
          kasvattini käy vähintään juniori-iässä yhden kerran virallisessa
          näyttelyssä saamassa laatuarvostelun. Olen lupautunut esittämään
          kasvattini ja ohjeistamaan tässä näyttelyyn valmistautumisessa.
        </p>
        <p>
          TIEDOKSESI, ETTÄ EMME KOSKAAN MYY PENTUA TAI OTA VARAUKSIA VASTAAN
          TAPAAMATTA ENSIN HENKILÖKOHTAISESTI. EMME MYÖSKÄÄN VASTAA
          TEKSTIVIESTITIEDUSTELUIHIN. Olet tervetullut tutustumaan koiriimme ja
          kasvattajaan niin halutessasi. Otamme rodusta kiinnostuneet
          mielellämme vastaan.
        </p>
      </div>
    </div>
  );
};

export default PuppyInfo;
