import React from "react";
import "./ContactUs.scss";
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from "react-icons/fa";

const ContactUs = (props) => {
  return (
    <div id="contact-us">
      <div className="content">
        <div className="header">
          <img alt="Paw" src={require("../../assets/paw_left.svg")} />
          <h1>Yhteystiedot</h1>
        </div>
        <div className="first">
          <img
            className="desktop-img"
            alt="1"
            src={require("../../assets/dogs/giantpaws_gryscha.jpg")}
          />
          <div className="texts">
            <p>
              <strong>Kennel Giantpaw's</strong>
            </p>
            <img
              className="mobile-img"
              alt="1"
              src={require("../../assets/dogs/giantpaws_gryscha.jpg")}
            />
            <a href="mailto:info.giantpaw@gmail.com">
              <FaEnvelope className="icon" />
              info.giantpaw@gmail.com
            </a>
            <a href="tel:+358445258741">
              <FaPhone className="icon" />
              +358 44 5258741
            </a>
            <div >
              <FaMapMarkerAlt className="icon" />
              Pori, Suomi
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
